import { createContext, useContext } from "react";
import { Event, StartUp, User } from "../types";

export type GlobalData = {

    startups: StartUp[],
    users: User[]

}

export const GlobalContext = createContext<
    {
        data: GlobalData
    }>({
        data: {
            startups: [],
            users: []
        },
    })

const useGlobalContext = (): {
    data: GlobalData,

} => {
    const context = useContext(GlobalContext)
    return context
}

export default useGlobalContext

