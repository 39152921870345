import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-native-paper';
import { InvestmentRecordNavParams } from './NavigationTypes';
import { Document } from '../types';
import DocumentForm from '../screens/forms/DocumentForm';
import BackArrow from '../components/icons/BackArrow';
import { getAllDocumentsByInvestmentRecordListener } from '../firestore_sync';
import { InvestmentRecordContext, InvestmentRecordData } from '../context/InvestmentRecordContext';
import useStartupContext from '../context/StartupContext';
import { InvestmentReecordUidContext } from '../context/route/InvestmentRecordContext';
export default function InvestmentRecordNavigator() {
  const [recordUid, setRecordUid] = useState<string | undefined>()
  const startupData = useStartupContext()
  const [data, setData] = useState<InvestmentRecordData>({
    record: undefined,
    documents: []
  })

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      record: startupData.data.investmentRecords.find(e => e.uid === recordUid),
      documents: []
    }))
  }, [recordUid, startupData.data.investmentRecords])

  useEffect(() => {
    if (data.record && data.record.uid === recordUid) {
      const unsubscribe = getAllDocumentsByInvestmentRecordListener(data.record.uid, (newDocs: Document[]) => {
        console.log(`NAV Investment Documents updated from firebase for startup ${startupData.data.startup?.name} and record ${data.record?.recordDate}: ${newDocs.length}`)
        setData(prevData => ({ ...prevData, documents: newDocs }))
      })
      return () => {

        console.log(`NAV UNSUBSCRIBE from investment  documents for startup ${startupData.data.startup?.name} and record ${data.record?.title}`)
        unsubscribe()
      }
    }
  }, [data.record, recordUid])

  const screenTitle = (prefix: string, suffix: string, altText: string) => {
    if (startupData.data.startup) {
      return `${prefix} ${startupData.data.startup.name} ${suffix}`
    } else {
      return `${altText}`
    }
  }

  return (
    <InvestmentReecordUidContext.Provider value={{ recordUid, setRecordUid }}>
      <InvestmentRecordContext.Provider value={{
        data
      }}>
          <InvestmentRecordStack.Navigator>
            <InvestmentRecordStack.Screen name="CreateDocumentInvestment"
              options={({ route }) => ({
                headerTitle: () => <Title>{screenTitle('', 'Investment Document - Create', '-')}</Title>,
                headerLeft: () => <BackArrow
                  startupUid={route.params?.startupUid} />
              })}
            >
              {({ route }) => (
                <>
                  <DocumentForm
                    investmentRecordUid={route.params?.recordUid}
                    startupUid={route.params?.startupUid}
                  />
                </>
              )}
            </InvestmentRecordStack.Screen>
            <InvestmentRecordStack.Screen name="EditDocumentInvestment"
              options={({ route }) => ({
                headerTitle: () => <Title>{screenTitle('', 'Investment Document - Edit', '-')}</Title>,
                headerLeft: () => <BackArrow
                  startupUid={route.params?.startupUid} />
              })}
            >
              {({ route }) => (
                <>
                  <DocumentForm
                    investmentRecordUid={route.params?.recordUid}
                    startupUid={route.params?.startupUid}
                    documentUid={route.params?.documentUid}
                  />
                </>
              )}
            </InvestmentRecordStack.Screen>
          </InvestmentRecordStack.Navigator>
      </InvestmentRecordContext.Provider>
    </InvestmentReecordUidContext.Provider >
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const InvestmentRecordStack = createNativeStackNavigator<InvestmentRecordNavParams>();
