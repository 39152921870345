import React from 'react'
import { useNavigation} from '@react-navigation/native'
import { Pressable } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import Colors from '../../styles/colors'

export type EditStartupIconProps = {
    startupUid: string
}

const EditStartupIcon: React.FC<EditStartupIconProps> = ({startupUid}) => {

    const navigation = useNavigation()

    const onIconPress = () => {
        navigation.navigate('StartupScreens', { screen: 'EditStartup', params: { startupUid } })
    } 

    return (
        <Pressable
            onPress={onIconPress}
            style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                paddingLeft: 10,
            })}>
            <FontAwesome
                name="pencil"
                size={25}
                color={'black'}
                style={{ marginRight: 10 }}
            />
        </Pressable>
    )
}

export default EditStartupIcon