import { DateTime } from "luxon";
import { Button } from "react-native-paper";
import { calculateDuration } from "../components/DurationFunctions";
import useGlobalContext from "../context/GlobalContext";
import { getAllEventsInFirestore } from "../firestore/event";
import { getInvestmentRecordsInFirestore } from "../firestore/investmentrecord";
import { getAllUsersInFirestore } from "../firestore/user";
import { convert as generateCsv } from '../util/json2csv'
import { useNavigation } from "@react-navigation/native";

export type StatsProps = {

}
const Stats: React.FC<StatsProps> = () => {
    const { data } = useGlobalContext()

    const navigation = useNavigation()

    const eventStageToString = (stage:string) => {
        switch(stage){
            case 'lead': return `01-Lead`
            case 'prospect': return `02-Prospect`
            case 'pitch': return `03-Pitch`
            case 'scopingSession': return `04-Scoping-Session`
            case 'proposalPrep': return `05-Proposal-Prep`
            case 'commercialNegotiation': return `06-Commerical-Negotiation`
            case 'POCBuild': return `07-POC-Build`
            case 'POCTesting': return `08-POC-Testing`
            case 'MVPBuild': return `09-MVP-Build`
            case 'MVPTesting': return `10-MVP-Testing`
            case 'exit': return `11-exit`
            default: return `99-${stage}`
        }
    }
    const downloadEventsCsv = async () => {
        const rows: { startup_id: string; startup_name: string; startup_assignee: string; startup_date_created: string; lead_source: string;
             stage: "lead" | "prospect" | "pitch" | "scopingSession" | "proposalPrep" | "commercialNegotiation" | "POCBuild" | "POCTesting" | "MVPBuild" | "MVPTesting" | "exit"; title: string; start: string; end: string; duration_days: number; }[] = []
        const users = await getAllUsersInFirestore()
        await Promise.all(
            data.startups
                .filter(s=>s.name.toLowerCase()!=='nivo')
                .map(async (s) => {
                    console.log('--startup', s)
                    const events = await getAllEventsInFirestore(s.uid)
                    let phase = 'NA';
                    phase = s.inProgress ? 'IN_PROGRESS' : phase;
                    phase = s.investment ? 'INVESTMENT' : phase;
                    phase = s.earlyPipeline ? 'PIPELINE' : phase;
                    phase = s.nonCommercial ? 'NON_COMMERCIAL' : phase;
                    phase = s.archived ? 'ARCHIVED' : phase;
                    phase = s.incubator ? 'INCUBATOR' : phase;

                    const mainAssigneeUid = s.assignees.length > 0 ? s.assignees[0] : 'NA'
                    const assignees = users?.filter(u => u.uid === mainAssigneeUid)
                    const mainAssignee = assignees && assignees.length > 0 ? assignees[0].email : mainAssigneeUid
                    events?.forEach((e) => {
                        let durationDays = -1
                        if (e.startDate && e.endDate) {
                            durationDays = calculateDuration(e.endDate, e.startDate)
                        } else if (e.startDate && !e.endDate) {
                            durationDays = calculateDuration(DateTime.now(), e.startDate)
                        }
                        console.log('--event', e)
                        const row = {
                            startup_id: s.uid,
                            startup_name: s.name,
                            startup_assignee: mainAssignee,
                            startup_date_created: s.dateCreated.toISO(),
                            phase: phase,
                            lead_source: s.leadSource ? s.leadSource : 'NA',
                            stage: eventStageToString(e.stage),
                            title: e.title,
                            start: e.startDate ? e.startDate.toISO() : 'NA',
                            end: e.endDate ? e.endDate.toISO() : 'NA',
                            duration_days: durationDays

                        }
                        rows.push(row)
                    })
                    return;
            })
        )
        generateCsv(rows, `events-${DateTime.now().toISODate()}.csv`)
    }


    const downloadStartupCsv = async () => {
        const rows: { startup_id: string; startup_name: string; startup_assignee: string; startup_date_created: string; lead_source: string; month_year: string; }[] = []
        const users = await getAllUsersInFirestore()
        await Promise.all(
            data.startups
                .filter(s=>s.name.toLowerCase()!=='nivo')
                .map(async (s) => {
                    console.log('--startup', s)
                    let phase = 'NA';
                    phase = s.inProgress ? 'IN_PROGRESS' : phase;
                    phase = s.nonCommercial ? 'NON_COMMERCIAL' : phase;
                    phase = s.investment ? 'INVESTMENT' : phase;
                    phase = s.earlyPipeline ? 'PIPELINE' : phase;
                    phase = s.archived ? 'ARCHIVED' : phase;
                    phase = s.incubator ? 'INCUBATOR' : phase;
                    const mainAssigneeUid = s.assignees.length > 0 ? s.assignees[0] : 'NA'
                    const assignees = users?.filter(u => u.uid === mainAssigneeUid)
                    const mainAssignee = assignees && assignees.length > 0 ? assignees[0].email : mainAssigneeUid

                    const row = {
                        startup_id: s.uid,
                        startup_name: s.name,
                        phase: phase,
                        startup_assignee: mainAssignee,
                        startup_date_created: s.dateCreated.toISO(),
                        lead_source: s.leadSource ? s.leadSource : 'NA',
                        month_year: `${s.dateCreated.year}-${s.dateCreated.month<10?'0':''}${s.dateCreated.month}`,
                        contact: s.contact
                    }
                    rows.push(row)
                    return;
            })
        )
        generateCsv(rows, `startups-${DateTime.now().toISODate()}.csv`)
    }


    const downloadInvestmentsCsv = async () => {
        const rows: { startup_id: string; startup_name: string; startup_assignee: string; startup_date_created: string; lead_source: string; month_year: string; }[] = []
        const users = await getAllUsersInFirestore()
        await Promise.all(
            data.startups.filter(s=>s.investment)
                         .filter(s=>s.name.toLowerCase()!=='nivo')
                         .map(async (s) => {
                const mainAssigneeUid = s.assignees.length > 0 ? s.assignees[0] : 'NA'
                const assignees = users?.filter(u => u.uid === mainAssigneeUid)
                const mainAssignee = assignees && assignees.length > 0 ? assignees[0].email : mainAssigneeUid
                const investmentRecords = await getInvestmentRecordsInFirestore(s.uid, 10)
                console.log('investment records', s.name, investmentRecords)
                const lastestRecord = investmentRecords && investmentRecords.length>0 ? investmentRecords[0] : undefined
                const row = {
                    startup_id: s.uid,
                    startup_name: s.name,
                    startup_assignee: mainAssignee,
                    startup_date_created: s.dateCreated.toISO(),
                    lead_source: s.leadSource ? s.leadSource : 'NA',
                    record_date: lastestRecord?.recordDate ? lastestRecord?.recordDate.toISODate() : undefined,
                    curent_valuation: lastestRecord?.currentValuation ? lastestRecord?.currentValuation * 1000 : 0.0,                   
                    shareholding_percentage: lastestRecord?.shareholdingPercentage,
                    current_tsf_value: lastestRecord?.currentValuation && lastestRecord?.shareholdingPercentage ? lastestRecord?.currentValuation * 1000 * lastestRecord?.shareholdingPercentage / 100 : 0.0,
                    tsf_cost: lastestRecord?.tsfCost ? lastestRecord?.tsfCost * 1000 : 0.0,
                    tsf_initial_share_value: lastestRecord?.tsfInvestmentValue ? lastestRecord?.tsfInvestmentValue * 1000 : 0,
                }
                rows.push(row)
                return;
            })
        )
        generateCsv(rows, `investment-${DateTime.now().toISODate()}.csv`)
    }


    return (<>
        <Button
            color={'black'}
            mode={'outlined'}
            style={{ width: 200, padding: 5, margin: 5 }}
            onPress={() => downloadStartupCsv()}
            icon={"trending-up"}
        >
            Startups CSV
        </Button>
        <Button
            color={'black'}
            mode={'outlined'}
            style={{ width: 200, padding: 5, margin: 5 }}
            onPress={() => downloadEventsCsv()}
            icon={"trending-up"}
        >
            Events CSV
        </Button>
        <Button
            color={'black'}
            mode={'outlined'}
            style={{ width: 200, padding: 5, margin: 5 }}
            onPress={() => downloadInvestmentsCsv()}
            icon={"trending-up"}
        >
            Investments CSV
        </Button>
        <Button
            color={'black'}
            mode={'outlined'}
            style={{ width: 200, padding: 5, margin: 5 }}
            onPress={() => navigation.navigate('HealthCheck')}
            icon={"trending-up"}
        >NX Health</Button>
    </>)
}

export default Stats