import { createContext, useContext } from "react";


export const EventUidContext = createContext<
    {
        eventUid: string | undefined,
        setEventUid: (uid: string | undefined) => void
    }>({
        eventUid: undefined,
        setEventUid: (uid: string | undefined) => { }
    })

const useEventUidContext = (): {
    eventUid: string | undefined,
    setEventUid: (uid: string | undefined) => void
} => {
    const context = useContext(EventUidContext)
    return context
}

export default useEventUidContext

