import React from 'react'
import { useOnCellActiveAnimation } from 'react-native-draggable-flatlist'
import { TouchableOpacity } from 'react-native-gesture-handler'
import EventTimeLineCard from './EventTimeLineCard'
import { Event, StartUp } from '../../types';
import { isMobile } from '../../util/IsMobile';

export type DraggableEventCardProps = {
    event: Event,
    startup: StartUp,
    drag: () => void,
}

const DraggableEventCard: React.FC<DraggableEventCardProps> = ({ event, startup, drag }) => {
    const { isActive } = useOnCellActiveAnimation()

    return (
        <TouchableOpacity
            onLongPress={drag}
            disabled={isActive || isMobile()}
        >
            <EventTimeLineCard event={event} startup={startup} lastCompleted={false} />
        </TouchableOpacity>
    )
}

export default DraggableEventCard