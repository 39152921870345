import React, { useState, useEffect } from "react"
import { Event, Phase, StartUp, User, InvestmentRecord } from "../../types"
import { Card, Caption, Subheading } from 'react-native-paper'
import Colors from '../../styles/colors'
import { TouchableOpacity, Linking } from 'react-native';
import { ScaleDecorator, useOnCellActiveAnimation } from "react-native-draggable-flatlist";
import styles from "../../styles/generic.styles";
import StartupLogo from '../StartupLogo'
import { useNavigation } from "@react-navigation/native";
import { isMobile } from "../../util/IsMobile";
import { FontAwesome } from '@expo/vector-icons'
import { Duration } from "luxon";
import TimelineInvestment from "./TimelineInvestment";
import TimelineArchived from "./TimelineArchived";
import TimelineInProgress from './TimelineInProgress'
import { StartupContext, StartupData } from "../../context/StartupContext";
import { getEventsListener } from "../../firestore_sync";
import { getLatestInvestmentRecordsListener } from "../../firestore/investmentrecord";

export type DashboardRowProps = {
    startup: StartUp,
    drag: () => void,
    users: User[],
    highlightDuration: Duration,
    phase: Phase
}

const DashboardRow: React.FC<DashboardRowProps> = ({ startup, drag, users, highlightDuration, phase }) => {

    const { isActive } = useOnCellActiveAnimation()
    const navigation = useNavigation()
    const [startupData, setStartupData] = useState<StartupData>({
        events: [],
        investmentRecords: [],
        documents: []
    })

    useEffect(() => {
        if (startup) {
            const unsubscribe = getEventsListener(startup.uid, (newEvents: Event[]) => {
                console.log(`Events updated from firebase for startup ${startup?.name}: ${newEvents.length}`)
                setStartupData(prevData => ({ ...prevData, events: newEvents }))
            })
            return () => {

                console.log(`DASH UNSUBSCRIBE from events for startup ${startup?.name}`)
                unsubscribe()
            }

        }

    }, [startup])

    useEffect(() => {
        if (startup && phase === 'investment') {
            const unsubscribe = getLatestInvestmentRecordsListener(startup.uid, (newRecords: InvestmentRecord[]) => {
                console.log(`Investment records updated from firebase for startup ${startup?.name}: ${newRecords.length}`)
                setStartupData(prevData => ({ ...prevData, investmentRecords: newRecords }))
            })
            return () => {

                console.log(`DASH UNSUBSCRIBE from investment records for startup ${startup?.name}`)
                unsubscribe()
            }

        }

    }, [startup])

    const onStartupNameClick = (startup: StartUp) => {
        navigation.navigate('StartupScreens', { screen: 'StartUpDetails', params: { startupUid: startup.uid } })
    }

    const urlOnClick = async () => {
        if (startup && startup.url) {
            Linking.openURL(startup.url)
        }
    }
    const formatAssignees = (startup: StartUp) => {
        let result = ""
        users.filter(u => startup.assignees.includes(u.uid)).map(u => {
            result = result + u.name.substring(0, u.name.indexOf(" ")) + ", "
        })
        return result ? result.substring(0, result.length - 2) : "None"
    }

    const getTimelineComponent = () => {
        switch (phase) {
            case 'investment':
                return <TimelineInvestment startup={startup} events={startupData.events} highlightDuration={highlightDuration} phase={phase} />
            case 'archived':
                return <TimelineArchived startup={startup} events={startupData.events} highlightDuration={highlightDuration} phase={phase} />
            case 'in-progress':
            default:
                return <TimelineInProgress startup={startup} events={startupData.events} highlightDuration={highlightDuration} phase={phase} />
        }
    }

    return (
        <StartupContext.Provider value={{
            data: startupData
        }}>
            <Card style={{
                backgroundColor: Colors.Tertiary,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 5,
                marginTop: 5
            }}>
                <Card.Content style={{ paddingBottom: 0 }}>
                    <ScaleDecorator>
                        <div style={{ display: isMobile() ? 'grid' : 'grid' }}>

                            <div style={{ flexDirection: 'row', width: '100%', paddingRight: 10, paddingBottom: 0 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', float: 'left' }}>
                                    <TouchableOpacity
                                        onLongPress={drag}
                                        disabled={isActive || isMobile()}
                                    >
                                        <div style={{ width: '300px', maxWidth: '300px', paddingRight: '20px' }}>
                                            {/* {!isMobile() && ( */}
                                            <StartupLogo startup={startup} width={300} height={100} onPress={() => onStartupNameClick(startup)} />
                                            {/* )} */}
                                            <div style={{ paddingTop: '10px' }}>
                                                <Subheading
                                                    style={styles.subheading}
                                                    onPress={() => onStartupNameClick(startup)}>{startup.name}

                                                </Subheading>
                                                {startup.url && (
                                                    <div style={{ float: 'right', paddingRight: '10px' }}>
                                                        <FontAwesome
                                                            name={"safari"}
                                                            size={16}
                                                            color={"black"}
                                                            onPress={urlOnClick}
                                                        />
                                                    </div>
                                                )}

                                            </div>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <Caption
                                                    style={styles.smallText}
                                                >assigned to: {formatAssignees(startup)}, modified: {startup.lastModified.toISODate()}
                                                </Caption>


                                            </div>

                                        </div>
                                    </TouchableOpacity>
                                </div>
                                {getTimelineComponent()}
                            </div>
                        </div>
                    </ScaleDecorator>
                </Card.Content >
            </Card >
        </StartupContext.Provider>
    )

}

export default DashboardRow