import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Comment } from '../../types'
import { v4 as uuidv4 } from 'uuid';
import { View } from 'react-native'
import { Formik, FormikHelpers } from 'formik'
import TextInputValidated from '../../components/forms/TextInputValidated'
import ActionButton from "../../components/ActionButton";
import styles from '../../styles/generic.styles';
import { DateTime } from 'luxon';
import { auth } from '../../FirebaseSingleton';
import { setCommentInFirstore } from '../../firestore_sync';
import { updateLastModified as updateLastModifiedStartup } from '../../firestore/startups';
import { updateLastModified as updateLastModifiedEvent } from '../../firestore/event';
import useStartupUidContext from '../../context/route/StartupUidContext';
import useEventUidContext from '../../context/route/EventUidContext';
export type CommentFormProps = {
    eventUid: string,
    startupUid: string,
}

const CreateCommentFormSchema = Yup.object({
    comment: Yup.string().required('Required')
})

type CreateCommentFormEntry = Yup.Asserts<typeof CreateCommentFormSchema>

const CreateCommentForm: React.FC<CommentFormProps> = ({ startupUid, eventUid }) => {
    const handleSubmit = async (
        values: {
            comment: string,
        },
        formikActions: FormikHelpers<{
            comment: string,
        }>) => {
        const cleanValues: CreateCommentFormEntry = CreateCommentFormSchema.validateSync(values)
        try {
            if (auth.currentUser) {
                const currentDateTime = DateTime.now().toUTC()
                const newComment: Comment = {
                    uid: uuidv4(),
                    eventUid: eventUid,
                    startupUid: startupUid,
                    comment: cleanValues.comment,
                    dateCreated: currentDateTime.toUTC(),
                    user: auth.currentUser.uid
                }
                await setCommentInFirstore(newComment)
                values.comment = ''
                await updateLastModifiedStartup(startupUid)
                await updateLastModifiedEvent(startupUid, eventUid)
            }
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <View>
            <Formik
                validatationSchema={CreateCommentFormSchema}
                initialValues={{
                    comment: '',
                }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <View>
                        <TextInputValidated
                            onChangeText={props.handleChange('comment')}
                            onBlur={props.handleBlur('comment')}
                            value={props.values.comment}
                            placeholder={"Write your comment here"}
                            label={""}
                            touched={props.touched.comment}
                            error={props.errors.comment}
                            multiLine={true}
                        />
                        {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> */}
                        <ActionButton
                            onPress={props.handleSubmit}
                            loading={props.isSubmitting}
                            style={styles.button}
                            disabled={props.isSubmitting}
                        >
                            Save Comment
                        </ActionButton>
                        {/* </div> */}
                    </View>
                )}
            </Formik>
        </View>
    )
}

export default CreateCommentForm