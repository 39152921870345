import { collection, doc, getDocs, setDoc, query, deleteDoc, onSnapshot, orderBy, limit } from "firebase/firestore";
import { compact } from "lodash";
import decodeWith from "../decodeHelper";
import { db } from "../FirebaseSingleton";
import { Comment, CommentT } from "../types";


export const setCommentInFirstore = async (comment: Comment): Promise<void> => {
    await setDoc(doc(db, `/startups/${comment.startupUid}/events/${comment.eventUid}/comments/`, comment.uid),
        CommentT.encode(comment))
    return new Promise((resolve) => resolve())
}

export const getCommentsListener = (startupUid: string, eventUid: string, callback: (comment: Comment[]) => void, limitCnt = 20) => {
    const q = (query(collection(db, `/startups/${startupUid}/events/${eventUid}/comments`), orderBy('dateCreated', 'desc'), limit(limitCnt)))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const comments: Comment[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(CommentT, doc.data())
        ))
        callback(comments)
    })
    return unsub
}

export const getAllCommentsByEventInFirestore = async (startupUid: string, eventUid: string): Promise<Comment[] | undefined> => {
    try {
        const snap = await getDocs(query(collection(db, `/startups/${startupUid}/events/${eventUid}/comments`)))
        const { docs } = snap
        return compact(docs.map((doc) => decodeWith(CommentT, doc.data())))
    } catch (err) {
        console.error(err)
    }
}

export const deleteCommentsInFirestore = async (comment: Comment): Promise<void> => {
    try {
        await deleteDoc(doc(db, `/startups/${comment.startupUid}/events/${comment.eventUid}/comments`, comment.uid))
        return new Promise((resolve) => resolve())
    } catch (err) {
        console.error(err)
    }
}