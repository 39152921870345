import { compact } from 'lodash'
import {
  collection, query, getDocs, getDoc, doc, setDoc, onSnapshot,
} from 'firebase/firestore'
import { db } from '../FirebaseSingleton'
import { decodeWith } from '../decodeHelper'
import { User, UserT } from '../types'


export const createUserInFirestore = async (user: User): Promise<void> => {
  const ref = doc(db, '/users/', user.uid)
  void setDoc(ref, user)
  return new Promise((resolve) => resolve())
}

export const getUserInFirestore = async (uid: string): Promise<User | undefined> => {
  try {
    const snap = await getDoc(doc(db, `/users`, uid))
    if (!snap.exists) {
      throw new Error('Could not find user')
    }
    const user = decodeWith(UserT, snap.data())
    return user
  } catch (err) {
    console.error(err)
  }
}

export const getAllUsersInFirestore = async (): Promise<User[] | undefined> => {
  try {
    const snap = await getDocs(query(collection(db, '/users')))
    const { docs } = snap
    return compact(docs.map((doc) => decodeWith(UserT, doc.data())))
  } catch (err) {
    console.error(err)
  }
}


export const getUsersListener = (callback: (users: User[]) => void) => {

  const q = query(collection(db, '/users'))
  const unsub = onSnapshot(q, (snapshot) => {
    const { docs } = snapshot
    const startups: User[] = compact(docs.map(
      (doc: { data: () => unknown }) => decodeWith(UserT, doc.data()),
    ))
    callback(startups)
  })
  return unsub

}