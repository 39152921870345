import { DateTime } from 'luxon';

export const calculateDuration = (end: DateTime, start: DateTime): number => {
  const duration = Math.floor(end.diff(start).as('days'))
  return (duration < 0) ? 0 : duration
}

export const formatDuration = (totalDays: number) => {
  let years = 0;
  let months = 0;
  let days = totalDays;
  if (totalDays > 365) {
      years = Math.floor(totalDays / 365);
      days = totalDays - (years * 365)
  }
  if (days > 30) {
      months = Math.floor(days / 30);
      days = days - (months * 30)
  }
  let result = days + "d"
  if (months > 0) {
      result = months + "m"
  }
  if (years > 0) {
      result = years + "y"
  }
  return result;
}