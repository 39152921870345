import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-native-paper';
import { EventNavParams } from './NavigationTypes';
import { Comment, Document } from '../types';
import EventForm from '../screens/forms/EventForm';
import DocumentForm from '../screens/forms/DocumentForm';
import EventDetails from '../screens/EventDetails';
import BackArrow from '../components/icons/BackArrow';
import EditEventIcon from '../components/icons/EditEventIcon';
import { EventContext, EventData } from '../context/EventContext';
import { EventUidContext } from '../context/route/EventUidContext';
import useStartupContext from '../context/StartupContext';
import { getAllDocumentsByEventListener, getCommentsListener } from '../firestore_sync';
export default function EventNavigator() {
  const [eventUid, setEventUid] = useState<string | undefined>()

  const startupData = useStartupContext()
  const [data, setData] = useState<EventData>({
    event: undefined,
    documents: [],
    comments: []
  })



  useEffect(() => {
    console.log('loading event', startupData)
    setData(prevData => ({
      ...prevData,
      event: startupData.data.events.find(e => e.uid === eventUid),
      documents: [],
      comments: []
    }))
  }, [eventUid, startupData.data.events])

  useEffect(() => {
    if (data.event && data.event.uid === eventUid) {
      const unsubscribe = getCommentsListener(data.event.startupUid, data.event.uid, (newComments: Comment[]) => {
        console.log(`NAV Comments updated from firebase for startup ${startupData.data.startup?.name} and event ${data.event?.title}: ${newComments.length}`)
        setData(prevData => ({ ...prevData, comments: newComments }))
      })
      return () => {

        console.log(`NAV UNSUBSCRIBE from comments for startup ${startupData.data.startup?.name} and event ${data.event?.title}`)
        unsubscribe()
      }
    }



  }, [data.event, eventUid])

  useEffect(() => {
    if (data.event && data.event.uid === eventUid) {
      const unsubscribe = getAllDocumentsByEventListener(data.event.uid, (newDocs: Document[]) => {
        console.log(`NAV Documents updated from firebase for startup ${startupData.data.startup?.name} and event ${data.event?.title}: ${newDocs.length}`)
        setData(prevData => ({ ...prevData, documents: newDocs }))
      })
      return () => {

        console.log(`NAV UNSUBSCRIBE from documents for startup ${startupData.data.startup?.name} and event ${data.event?.title}`)
        unsubscribe()
      }
    }



  }, [data.event, eventUid])

  const screenTitle = (prefix: string, suffix: string, altText: string) => {
    if (startupData.data.startup && data.event) {
      return `${prefix} ${startupData.data.startup?.name} ${data.event.title} ${suffix}`
    } else if (startupData.data.startup) {
      return `${prefix} ${startupData.data.startup.name} ${suffix}`
    } else {
      return `${altText}`
    }
  }
  return (
    <EventUidContext.Provider value={{ eventUid, setEventUid }}>

      <EventContext.Provider value={{
        data
      }}>
        <EventStack.Navigator>


          <EventStack.Screen name="EditEvent"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Event -  Edit', 'Create Event')}</Title>,
              headerLeft: () => <BackArrow startupUid={route.params.startupUid} eventUid={route.params.eventUid} />
            })}
          >
            {({ route }) => (
              <>
                <EventForm
                  startupUid={route.params?.startupUid}
                  eventUid={route.params?.eventUid}
                />
              </>
            )}
          </EventStack.Screen>

          <EventStack.Screen name="EventDetails"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Event', 'Event')}</Title>,
              headerLeft: () => <BackArrow startupUid={route.params.startupUid} />,
              headerRight: () =>
                <EditEventIcon
                  startupUid={route.params.startupUid}
                  eventUid={route.params.eventUid}
                />
            })}>
            {({ route }) => (
              <>
                <EventDetails
                  startupUid={route.params.startupUid}
                  eventUid={route.params.eventUid}
                />
              </>
            )}
          </EventStack.Screen>
          <EventStack.Screen name="CreateDocumentEvent"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Document - Create', '-')}</Title>,
              headerLeft: () => <BackArrow eventUid={route.params?.eventUid}
                startupUid={route.params?.startupUid} />
            })}
          >
            {({ route }) => (
              <>
                <DocumentForm
                  eventUid={route.params?.eventUid}
                  startupUid={route.params?.startupUid}
                />
              </>
            )}
          </EventStack.Screen>
          <EventStack.Screen name="EditDocumentEvent"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Document - Edit', '-')}</Title>,
              headerLeft: () => <BackArrow eventUid={route.params?.eventUid}
                startupUid={route.params?.startupUid} />
            })}
          >
            {({ route }) => (
              <>
                <DocumentForm
                  eventUid={route.params?.eventUid}
                  startupUid={route.params?.startupUid}
                  documentUid={route.params?.documentUid}
                />
              </>
            )}
          </EventStack.Screen>


        </EventStack.Navigator>
      </EventContext.Provider>


    </EventUidContext.Provider>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const EventStack = createNativeStackNavigator<EventNavParams>();
