import { collection, getDocs } from "firebase/firestore";
import { db } from "../FirebaseSingleton";

type ProjectHealth =  {
  project: string,
  warnings: number,
  fixable_warnings: number,
  errors: number,
}

export type ProjectHealthRecord = Record<string, ProjectHealth>
export const getProjectHealthChecks = async (): Promise<Record<string, ProjectHealthRecord>> => {
  const q = collection(db, `nxHealth`)
  
  const { docs } = await getDocs(q);

  const data = docs.map((doc) => doc.data())
  const record: Record<string, ProjectHealthRecord> = {};
  data.forEach((project) => {
    const projectName = Object.values(project)[0].project
    if (projectName) {
        record[projectName] = project
    }
  })
  return record;
}

export type TodoRecord = Record<string, Record<string, string|number>>
export const getProjectTodos = async (): Promise<TodoRecord> => {
  const q = collection(db, 'nxTodos')
  const { docs } = await getDocs(q)
  const data = docs.map((doc) => ({
      [doc.id]: doc.data()
  })).flat() as TodoRecord[]
  const record = data.reduce((arg, data) => Object.assign(arg, { ...data }))
  return record     
}