import * as React from 'react'
import { ActivityIndicator } from 'react-native-paper'

const Loading: React.FC = () => (
  <ActivityIndicator
    style={{
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    }}
    size="large"
    color="blue"
  />
)

export default Loading
