import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Provider as PaperProvider, Caption, Title } from 'react-native-paper'
import { View } from 'react-native';
import LogIn from '../screens/LogIn';
import ModalScreen from '../screens/ModalScreen';
import { UnauthedNavParams, AuthedNavParams } from './NavigationTypes';
import { StartUp, User, UserT } from '../types';
import { auth, db } from '../FirebaseSingleton';
import { doc, onSnapshot } from 'firebase/firestore';
import decodeWith from '../decodeHelper';
import { AuthedLinking, UnAuthedLinking } from './LinkingConfiguration';
import StartupForm from '../screens/forms/StartupForm';
import PaperTheme from '../styles/theme';
import styles from '../styles/generic.styles';
import { useFonts } from 'expo-font'
import BackArrow from '../components/icons/BackArrow';
import StartupTimelines from '../screens/StartupTimelines';
import { getStartupsListener } from '../firestore_sync';
import { getUsersListener } from '../firestore/user';
import { GlobalContext, GlobalData } from '../context/GlobalContext';
import StartupNavigator from './StartupNavigator';
import Stats from '../screens/Stats';
import HealthCheck from '../screens/HealthCheck';

export default function Navigation() {

  const [userUid, setUserUid] = useState<string | undefined>(auth.currentUser?.uid)
  const [userLoading, setUserLoading] = useState<UserLoading>({ state: 'Loading' })

  const [data, setData] = useState<GlobalData>({
    startups: [],
    users: []
  })

  const useAppFonts = (): boolean => {
    /* eslint-disable */
    const [fontsLoaded] = useFonts({
      Book: require('../assets/fonts/sofiapro-light.otf'),
      Medium: require('../assets/fonts/sofiapro-light.otf'),
      Regular: require('../assets/fonts/sofiapro-light.otf'),
      Bold: require('../assets/fonts/sofiapro-light.otf'),
    })
    /* eslint-enable */
    return fontsLoaded
  }

  const fontsLoaded = useAppFonts()

  useEffect(() => {
    const timeout = setTimeout(() => {
      try {
        document.head.insertAdjacentHTML('beforeend', '<style>.firebase-emulator-warning{display:none}</style>')
      } catch (e) {
        console.log((e as Error).message || e)
      }
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (userLoading.state === 'Loading') {
      const timeout = setTimeout(() => { setUserLoading({ state: 'Unauthed' }) }, 2500)
      return () => {
        clearTimeout(timeout)
      }
    }
    return () => undefined
  }, [userLoading])

  useEffect(() => {
    auth.onAuthStateChanged((newFirebaseUser) => {
      setUserUid(newFirebaseUser?.uid)
      setUserLoading({ state: 'Loading' })
    })
  }, [])

  useEffect(() => {
    if (userUid) {

      const unsubLoggedInUser = onSnapshot(doc(db, 'users', userUid), (document) => {
        if (document.data()) {
          const user = decodeWith(UserT, document.data())
          if (user) {
            setUserLoading({ state: 'Authed', user })
          }
        }
      })
      return unsubLoggedInUser

    }

  }, [userUid])


  useEffect(() => {
    if (userUid) {
      const unsubStartups = getStartupsListener((newStartups: StartUp[]) => {
        console.log(`Startups updated from firebase: ${newStartups.length}`)
        setData(prevData => ({ ...prevData, startups: newStartups }))
      })
      return unsubStartups

    }

  }, [userUid])

  useEffect(() => {
    if (userUid) {
      const unsubUsers = getUsersListener((newUsers: User[]) => {
        console.log(`Users updated from firebase: ${newUsers.length}`)
        setData(prevData => ({ ...prevData, users: newUsers }))
      })
      return unsubUsers

    }

  }, [userUid])

  return (
    <View style={styles.appContainer}>
      <PaperProvider theme={PaperTheme}>
        {(userLoading.state === 'Loading' || !fontsLoaded) && (
          <Caption>Loading</Caption>
        )}
        {userLoading.state === 'Authed' && (
          <GlobalContext.Provider value={{
            data
          }}>
            <NavigationContainer
              linking={AuthedLinking}
            >
              <AuthedStack.Navigator>
                <AuthedStack.Screen name="InProgressHome" options={{ headerShown: false }} >
                  {() =>  <StartupTimelines phase={'in-progress'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="EarlyPipelineHome" options={{ headerShown: false }} >
                  {() =>  <StartupTimelines phase={'early-pipeline'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="InvestmentsHome" options={{ headerShown: false }} >
                  {() => <StartupTimelines phase={'investment'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="IncubatorHome" options={{ headerShown: false }} >
                  {() => <StartupTimelines phase={'incubator'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="NonCommercialHome" options={{ headerShown: false }} >
                  {() => <StartupTimelines phase={'non-commercial'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="ArchivedHome" options={{ headerShown: false }} >
                  {() => <StartupTimelines phase={'archived'} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="Stats"
                  options={() => ({
                    headerTitle: () => <Title>{'Stats'}</Title>,
                    headerLeft: () => <BackArrow startupUid={undefined} />
                  })}
                >
                  {() => <Stats />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="CreateStartup"
                  options={() => ({
                    headerTitle: () => <Title>{'Create Startup'}</Title>,
                    headerLeft: () => <BackArrow startupUid={undefined} />
                  })}
                >
                  {() => <StartupForm startupUid={undefined} />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="Modal" component={ModalScreen} />
                <AuthedStack.Screen name="StartupScreens" options={{ headerShown: false }} >
                  {() => <StartupNavigator />}
                </AuthedStack.Screen>
                <AuthedStack.Screen name="HealthCheck"
                  options={() => ({
                    headerTitle: () => <Title>{'Health Check'}</Title>,
                    headerLeft: () => <BackArrow startupUid={undefined} />
                  })}
                >
                   {() => <HealthCheck/>}
                </AuthedStack.Screen>
              </AuthedStack.Navigator>
            </NavigationContainer>
          </GlobalContext.Provider>
        )}
        {userLoading.state !== 'Loading' && userLoading.state === 'Unauthed' && (
          <NavigationContainer
            linking={UnAuthedLinking}
          >
            <UnauthedStack.Navigator>
              <UnauthedStack.Screen name="Home" component={LogIn} options={{ title: 'Home' }} />
            </UnauthedStack.Navigator>
          </NavigationContainer>
        )}
      </PaperProvider>

    </View >
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const AuthedStack = createNativeStackNavigator<AuthedNavParams>();
const UnauthedStack = createNativeStackNavigator<UnauthedNavParams>();

type UserLoading = LoadingUser | AuthedUser | UnauthedUser
type LoadingUser = {
  state: 'Loading'
}
type AuthedUser = {
  state: 'Authed',
  user: User
}
type UnauthedUser = {
  state: 'Unauthed'
}
