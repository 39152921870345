import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'
import { Linking, View } from 'react-native';
import ActionButton from '../components/ActionButton';
import CommentSection from '../components/comments/CommentSection';
import DocumentList from '../components/documents/DocumentList';
import styles from '../styles/generic.styles';
import { Caption, Paragraph, Title, Divider } from 'react-native-paper';
import { FontAwesome } from '@expo/vector-icons';
import useStartupContext from '../context/StartupContext';
import useStartupUidContext from '../context/route/StartupUidContext';
import useEventUidContext from '../context/route/EventUidContext';
import useEventContext from '../context/EventContext';
import Loading from '../components/Loading';

export type EventDetailProps = {
    eventUid: string;
    startupUid: string;
}

const EventDetails: React.FC<EventDetailProps> = ({ eventUid, startupUid }) => {
    const { setStartupUid } = useStartupUidContext()
    const { setEventUid } = useEventUidContext()

    useEffect(() => {
        setStartupUid(startupUid)
        setEventUid(eventUid)
    }, [eventUid, startupUid])


    const startupContext = useStartupContext()
    const eventContext = useEventContext()
    const startup = startupContext.data.startup
    const event = eventContext.data.event

    const navigation = useNavigation()

    const onCreateDocPress = () => {
        navigation.navigate('StartupScreens',
            {
                screen: 'EventScreens', params:
                {
                    screen: 'CreateDocumentEvent', params: {
                        eventUid: eventUid,
                        startupUid: startupUid
                    }
                }
            }
        )
    }

    if (!startup || !event) {
        return (
            <Loading />
        )
    }

    return (
        <View style={styles.formContainer}>
            {event && (
                <>
                    <Title style={styles.heading}>{startup?.name}: {event?.title}</Title>
                    <Caption>Last modified: {event?.lastModified ? event?.lastModified.toFormat('dd LLL yyyy HH:mm') : '-'}</Caption>

                    <Paragraph>Start date: {event?.startDate ? event?.startDate.toFormat('dd LLL yyyy HH:mm') : '-'}</Paragraph>
                    <Paragraph>End date: {event?.endDate ? event?.endDate.toFormat('dd LLL yyyy HH:mm') : '-'}</Paragraph>
                    <Paragraph>Expected end date: {event?.expectedEndDate ? event?.expectedEndDate.toFormat('dd LLL yyyy HH:mm') : '-'}</Paragraph>
                    <Paragraph style={styles.normalText}>
                        <b>Jira link: {event.projectTrackingUrl ? '' : ' not set'}</b>
                        {event.projectTrackingUrl && (<FontAwesome
                            name="trello"
                            onPress={() => Linking.openURL(event.projectTrackingUrl!)}
                            size={18}
                            color={'black'}
                            style={{ marginHorizontal: 15 }}
                        />)}
                    </Paragraph>
                    <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />

                    <DocumentList event={event} />
                    <ActionButton style={styles.button} onPress={() => onCreateDocPress()}>
                        Add Document
                    </ActionButton>
                    <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />

                    <CommentSection startupUid={startupUid} eventUid={eventUid} />
                </>
            )}

        </View>
    )
}

export default EventDetails