import { FontAwesome } from '@expo/vector-icons'
import React from 'react'
import { Event } from '../../types'

export type EventIconProps = {
    event: Event,
    color: string
}

const EventIcon: React.FC<EventIconProps> = ({ event, color }) => {

    const getIcon = () => {
        switch (event.stage) {
            case 'lead':
                return ('question')
            case 'prospect':
                return ('comment')
            case 'pitch':
                return ('volume-up')
            case 'scopingSession':
                return ('search')
            case 'proposalPrep':
                return ('volume-up')
            case 'commercialNegotiation':
                return ('comments')
            case 'POCBuild':
            case 'MVPBuild':
                return ('wrench')
            case 'POCTesting':
            case 'MVPTesting':
                return ('bug')
            case 'exit':
                return ('sign-out')
            default:
                return ('question')
        }
    }


    return (
        <FontAwesome
            name={getIcon() as any}
            size={22}
            color={color}
            style={{ marginRight: 15 }}
        />
    )
}

export default EventIcon