import { Event, StartUp, Comment } from '../types'

export const sortCommentsByDate = (listToSort: Comment[]): Comment[] => {
    const sorted_list = listToSort.sort((a, b) => {
        return a.dateCreated.toJSDate().getTime() -
            b.dateCreated.toJSDate().getTime()
    })
    return sorted_list
}

export const sortEventsByDate = (listToSort: Event[]): Event[] => {
    const sorted_list = listToSort.sort((a, b) => {
        if (a.startDate && b.startDate) {
            return a.startDate.toJSDate().getTime() -
                b.startDate.toJSDate().getTime()
        }
        return 0
    })
    return sorted_list
}

export const sortEventsByIndex = (listToSort: Event[]): Event[] => {
    const sorted_list = listToSort.sort((a, b) => {
        return a.indexOrder - b.indexOrder
    })
    return sorted_list
}

export const sortStartupsByIndex = (listToSort: StartUp[]): StartUp[] => {
    const sorted_list = listToSort.sort((a, b) => {
        return a.order - b.order
    })
    return sorted_list
}

export const insertOrReplaceEvent = (list: Event[], newEvent: Event, reorder?: boolean): Event[] => {
    let previousEvent: Event | undefined = undefined
    list.forEach((event) => {
        if (event.uid === newEvent.uid) previousEvent = event
    })
    if (reorder) {
        list.forEach((event) => {
            if (event.indexOrder >= newEvent.indexOrder) {
                event.indexOrder++
            }
        })
    }
    if (previousEvent) {
        list[list.indexOf(previousEvent)] = newEvent
    } else {
        list.push(newEvent)
    }
    const toReturn = sortEventsByIndex(list)
    return toReturn
}