import { configureFonts, DefaultTheme } from 'react-native-paper'
import { Fonts } from 'react-native-paper/src/types'
import Colors from '../styles/colors'

const InternalConfig: Fonts = {
  regular: {
    fontFamily: 'Regular',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Medium',
    fontWeight: 'normal',
  },
  light: {
    fontFamily: 'Book',
    fontWeight: 'normal',
  },
  thin: {
    fontFamily: 'Book',
    fontWeight: 'normal',
  },
}

const fontConfig = {
  ios: InternalConfig,
  android: InternalConfig,
  web: InternalConfig,
}

export const PaperTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.Primary,
    border: Colors.Secondary,
    background: Colors.Background,
    paper: Colors.Background
  },
  fonts: configureFonts(fontConfig),
  roundness: 10,
}

export default PaperTheme
