import { createContext, useContext } from "react";
import { Comment, Document, Event } from "../types";

export type EventData = {
    event: Event | undefined
    comments: Comment[]
    documents: Document[],

}

export const EventContext = createContext<
    {
        data: EventData
    }>({
        data: {
            event: undefined,
            comments: [],
            documents: [],
        },
    })

const useEventContext = (): {
    data: EventData,

} => {
    const context = useContext(EventContext)
    return context
}

export default useEventContext

