import React, { useState, FC } from 'react'
import DropDown from 'react-native-paper-dropdown'
import { User } from '../../types'
import { isMobile } from '../../util/IsMobile'

export type SelectAssigneesDropdownProps = {
  selectedAssignees: string,
  setSelectedAssignees: (assigneees: string) => void
  users: User[]
}

const SelectAssigneesDropdown: FC<SelectAssigneesDropdownProps> = ({ selectedAssignees, setSelectedAssignees, users }) => {
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false)

  return (
    <div style={{ width: isMobile() ? '80%' : '80%' }}>
      <DropDown
        label={"Assignees"}
        mode={"outlined"}
        visible={showMultiSelectDropDown}
        showDropDown={() => setShowMultiSelectDropDown(true)}
        onDismiss={() => setShowMultiSelectDropDown(false)}
        value={selectedAssignees}
        setValue={setSelectedAssignees}
        list={users.map(
          user => {
            return {
              label: user.name,
              value: user.uid,
            }
          }
        )}
        multiSelect
      />
    </div>
  )
}

export default SelectAssigneesDropdown
