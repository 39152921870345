import { Event, stages } from "../../types"
import React, { useState, useEffect } from 'react'
import { setEventInFirestore, deleteEventInFirestore } from "../../firestore_sync"
import { View } from 'react-native'
import { Caption, Snackbar } from 'react-native-paper';
import * as Yup from 'yup'
import type { Asserts } from 'yup'
import styles from "../../styles/generic.styles";
import { Formik, FormikHelpers } from 'formik'
import TextInputValidated from '../../components/forms/TextInputValidated'
import ActionButton from "../../components/ActionButton";
import { DateTime } from "luxon";
import { useNavigation } from "@react-navigation/native";
import { v4 as uuidv4 } from 'uuid';
import DropDown from "react-native-paper-dropdown";
import { auth } from "../../FirebaseSingleton"
import Loading from "../../components/Loading"
import DatePicker from "../../components/forms/DatePicker"
import { isMobile } from "../../util/IsMobile"
import DeleteConfirmModal from "../../components/DeleteConfirmModal"
import { updateLastModified as updateLastModifiedStartup } from '../../firestore/startups';
import { SubmitListener } from "./SubmitListener";
import useStartupContext from "../../context/StartupContext";
import useStartupUidContext from "../../context/route/StartupUidContext";
import useEventUidContext from "../../context/route/EventUidContext";
import { formatObjectForDropdown } from "../../util/DropdownListFormat";

export type EventFormProps = {
  startupUid: string;
  eventUid?: string;
  title?: string;
  indexOrder?: number;
}

const EventFormSchema = Yup.object({
  stage: Yup.string().trim().required('Required'),
  title: Yup.string().trim().required('Required'),
  indexOrder: Yup.number().required('Required'),
  projectTrackingUrl: Yup.string().trim().url(),
})

type EventFormEntry = Asserts<typeof EventFormSchema>

const EventForm: React.FC<EventFormProps> = ({ startupUid, eventUid, indexOrder }) => {
  const { setStartupUid } = useStartupUidContext()
  const { setEventUid } = useEventUidContext()

  useEffect(() => {
    setStartupUid(startupUid)
    setEventUid(eventUid)
  }, [])


  const navigation = useNavigation()
  const { data } = useStartupContext()

  const [loading, setLoading] = useState(true)
  const [notifyText, setNotifyText] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [event, setEvent] = useState<Event>({
    uid: uuidv4(),
    startupUid: startupUid,
    title: '',
    stage: 'lead',
    lastModified: DateTime.now().toUTC(),
    modifiedBy: '',
    indexOrder: indexOrder ? indexOrder : 0,
  });
  const [error, setError] = useState(false);
  const [openStagePicker, setOpenStagePicker] = useState(false)

  useEffect(() => {

    if (eventUid) {
      setLoading(true)
      const foundEvent = data.events.find(e => e.uid === eventUid)
      console.log('FOUND EVENT:' + JSON.stringify(foundEvent))
      if (foundEvent) {
        setError(false)
        setEvent(foundEvent)

      } else {
        setError(true)
      }

    }
    setLoading(false)

  }, [eventUid, startupUid, data])

  const handleSubmit = async (
    values: Event,
    formikActions: FormikHelpers<Event>) => {
    const cleanValues: EventFormEntry = EventFormSchema.validateSync(values)
    try {

      if (!cleanValues) {
        throw new Error('Failed to validate event details')
      }
      if (values.stage && auth.currentUser) {

        const newEvent = {
          ...event,
          title: cleanValues.title,
          indexOrder: cleanValues.indexOrder,
          startDate: values.startDate,
          endDate: values.endDate,
          expectedEndDate: values.expectedEndDate,
          expectedNextUpdateDate: values.expectedNextUpdateDate,
          lastModified: DateTime.now().toUTC(),
          modifiedBy: auth.currentUser.uid,
          stage: values.stage,
          projectTrackingUrl: values.projectTrackingUrl,
        }
        const newEventWithoutUndefinedFields = Object.create(newEvent)
        for (const [key, value] of Object.entries(newEvent)) {
          if (value !== undefined) {
            newEventWithoutUndefinedFields[key] = value;
          }
        }

        try {
          await setEventInFirestore(newEventWithoutUndefinedFields)
          setNotifyText('Changes saved successfully')
        } catch (e) {
          console.error(e)
          setNotifyText('Error saving data, please try again later. If the problem persists contact support')
        }
        await updateLastModifiedStartup(newEvent.startupUid)
      }
    } catch (err) {
      console.error(err)
      setNotifyText('Unexpected error. If the problem persists contact support')
    }
  }


  if (loading) {
    return (<Loading />)
  }
  if (error) {
    return (
      <View style={styles.screenContainer}>
        <Caption style={styles.error}>Error, could not find event with id</Caption>
      </View>
    )
  }

  return (<>
    <Snackbar
      visible={(notifyText && notifyText !== '') ? true : false}
      onDismiss={() => setNotifyText('')}
      duration={notifyText && notifyText.toLowerCase().includes('error') ? 300000 : 5000}
    >
      {notifyText}
    </Snackbar>

    <View style={styles.formContainer}>
      <Formik
        initialValues={event}
        validationSchema={EventFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {

          return (
            <View>
              <SubmitListener formikProps={props} />

              <TextInputValidated
                onChangeText={props.handleChange('title')}
                onBlur={props.handleBlur('title')}
                value={props.values.title}
                placeholder={"Type here"}
                label={"Event Title"}
                touched={props.touched.title}
                error={props.errors.title}
              />
              <TextInputValidated
                onChangeText={props.handleChange('projectTrackingUrl')}
                onBlur={props.handleBlur('projectTrackingUrl')}
                value={props.values.projectTrackingUrl ? props.values.projectTrackingUrl : ""}
                placeholder={"Type here"}
                label={"Jira link"}
                touched={props.touched.projectTrackingUrl}
                error={props.errors.projectTrackingUrl}
              />
              <TextInputValidated
                onChangeText={props.handleChange('indexOrder')}
                onBlur={props.handleBlur('indexOrder')}
                value={props.values.indexOrder.toString()}
                label={"Order on timeline"}
                placeholder={"Type here"}
                touched={props.touched.indexOrder}
                error={props.errors.indexOrder}
                editable={false}
              />

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <div style={{ width: isMobile() ? '80%' : '80%' }}>
                  <DropDown
                    label={"Stage"}
                    mode={"outlined"}
                    visible={openStagePicker}
                    showDropDown={() => setOpenStagePicker(true)}
                    onDismiss={() => setOpenStagePicker(false)}
                    value={props.values.stage}
                    setValue={(value) => {
                      props.setFieldValue('stage', value)
                    }}
                    list={formatObjectForDropdown(stages)}
                  />
                </div>
                <DatePicker
                  setToNullAllowed={true}
                  label='Start Date'

                  setterFunction={(value: DateTime | undefined) => {
                    props.setFieldValue('startDate', value)
                  }
                  }
                  selectedDate={
                    props.values.startDate
                  }
                />
                <DatePicker
                  setToNullAllowed={true}
                  label='Next update expected'

                  setterFunction={(value: DateTime | undefined) => {
                    props.setFieldValue('expectedNextUpdateDate', value)
                  }
                  }
                  selectedDate={
                    props.values.expectedNextUpdateDate
                  }
                />

                <DatePicker
                  setToNullAllowed={true}
                  label='Expected End Date'
                  setterFunction={(value: DateTime | undefined) => {
                    props.setFieldValue('expectedEndDate', value)
                  }
                  }
                  selectedDate={
                    props.values.expectedEndDate
                  }
                />
                <DatePicker
                  setToNullAllowed={true}
                  label='End Date'
                  setterFunction={(value: DateTime | undefined) => {
                    props.setFieldValue('endDate', value)
                  }
                  }
                  selectedDate={
                    props.values.endDate
                  }
                />
                {event && (
                  <>
                    <ActionButton
                      onPress={() => setShowDeleteModal(true)}
                      style={styles.destructiveButton}
                    >
                      Delete Event {event.title}
                    </ActionButton>
                    <DeleteConfirmModal confirmMessage={`Delete ${event.title} event?`} onConfirm={async () => {
                      await deleteEventInFirestore(event)
                      setShowDeleteModal(false)
                      navigation.navigate('StartupScreens', { screen: 'StartUpDetails', params: { startupUid: startupUid } })
                    }
                    } showModal={showDeleteModal} onDismissCallback={() => setShowDeleteModal(false)} />
                  </>
                )}
              </div>
            </View>
          )
        }}
      </Formik>
    </View >
  </>
  )

}

export default EventForm
