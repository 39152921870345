import { FontAwesome } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Pressable, View } from 'react-native'
import { Phase } from '../../types'
type BackArrowPros = {
    startupUid?: string
    eventUid?: string
    phase?: Phase
}
const BackArrow: React.FC<BackArrowPros> = ({ startupUid, eventUid, phase }) => {
    const navigation = useNavigation()

    const defaultBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack()
            return
        }
        if (eventUid && startupUid) {
            navigation.navigate('StartupScreens', {
                screen: 'EventScreens', params: { screen: 'EventDetails', params: { startupUid: startupUid, eventUid: eventUid } }
            })
            return
        }
        if (startupUid) {
            navigation.navigate('StartupScreens', {
                screen: 'StartUpDetails', params: { startupUid: startupUid }
            })
            return
        }
        const backPhase = phase ? phase : 'in-progress'
        switch (backPhase) {
            case 'investment':
                navigation.navigate('InvestmentsHome')
                break;
            case 'archived':
                navigation.navigate('ArchivedHome')
                break;
            case 'in-progress':
            default:
                navigation.navigate('InProgressHome')
                break;
        }


    }

    const goBack = () => {
        navigation.canGoBack() ? navigation.goBack() : defaultBack()
    }

    return (
        <View style={{ display: 'flex', flexDirection: 'row' }}>

            <Pressable
                onPress={() => goBack()}
                style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                    paddingLeft: 10,


                })}>
                <FontAwesome
                    name="arrow-left"
                    size={25}
                    color={"black"}
                    style={{ marginRight: 15, height: '100%' }}
                />
            </Pressable>
            <Pressable
                onPress={() => navigation.navigate('InProgressHome')}
                style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                    paddingLeft: 10,


                })}>
                <FontAwesome
                    name="home"
                    size={25}
                    color={"black"}
                    style={{ marginRight: 15, height: '100%' }}
                />
            </Pressable>

        </View >

    )
}

export default BackArrow