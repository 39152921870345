import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { AuthedNavParams, UnauthedNavParams } from './NavigationTypes';

const prefix = Linking.createURL('/');
export const AuthedLinking: LinkingOptions<AuthedNavParams> = {
  prefixes: [prefix],
  config: {
    screens: {
      EarlyPipelineHome: 'early-pipeline',
      InProgressHome: 'in-progress',
      InvestmentsHome: 'investments',
      ArchivedHome: 'archived',
      IncubatorHome: 'incubator',
      Stats: 'stats',
      CreateStartup: 'startup/create',
      Modal: 'modal',
      StartupScreens: {
        screens: {
          StartUpDetails: 'startup/:startupUid',
          EditStartup: 'startup/:startupUid/edit',
          CreateEvent: 'startup/:startupUid/event/create',
          CreateInvestmentRecord: 'startup/:startupUid/investmentrecord/create',
          EditTimeline: 'startup/:startupUid/edit-timeline',
          EventScreens: {
            screens: {
              EventDetails: 'startup/:startupUid/event/:eventUid',
              EditEvent: 'startup/:startupUid/event/:eventUid/edit',
              CreateDocumentEvent: 'startup/:startupUid/event/:eventUid/document/create',
              EditDocumentEvent: 'startup/:startupUid/event/:eventUid/document/:documentUid/edit',

            },
          },
          InvestmentRecordScreens: {
            screens: {
              CreateDocumentInvestment: 'startup/:startupUid/investmentrecord/:recordUid/document/create',
              EditDocumentInvestment: 'startup/:startupUid/investmentrecord/:recordUid/document/:documentUid/edit',

            },
          }

        },
      },
      HealthCheck: 'healthCheck',
    },
  }
};

export const UnAuthedLinking: LinkingOptions<UnauthedNavParams> = {
  prefixes: [prefix],
  config: {
    screens: {
      Home: '/',
    },
  }
}
