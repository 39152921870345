import React, { useState, useEffect } from 'react'
import { Card, Caption, Divider } from 'react-native-paper'
import { Tooltip } from 'react-hover-tooltip'
import { Document, StartUp, InvestmentRecord } from "../../types"

import { useNavigation } from '@react-navigation/native';
import { DateTime, Duration } from 'luxon';
import { FontAwesome } from '@expo/vector-icons'
import { Linking } from 'react-native';
import { calculateDuration, formatDuration } from '../DurationFunctions'
import { InvestmentRecordData } from '../../context/InvestmentRecordContext';
import useStartupContext from '../../context/StartupContext';
import { getAllDocumentsByInvestmentRecordListener } from '../../firestore_sync';

type CardSize = 'regular' | 'large'
export type InvestmentTimeLineCardProps = {
    startup: StartUp,
    highlightDuration?: Duration,
    size?: CardSize
}

const InvestmentTimeLineCard: React.FC<InvestmentTimeLineCardProps> = ({
    startup,
    highlightDuration = Duration.fromDurationLike({ days: 3 }),
    size = 'regular' }) => {
    const navigation = useNavigation();
    const { data: { investmentRecords } } = useStartupContext()
    const [investmentRecordData, setInvestmentRecordData] = useState<InvestmentRecordData>({
        documents: []
    })

    const investmentRecord: InvestmentRecord | undefined = investmentRecords && investmentRecords.length > 0 ? investmentRecords[0] : undefined
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        if (investmentRecord) {
            const unsubscribe = getAllDocumentsByInvestmentRecordListener(investmentRecord.uid, (newDocs: Document[]) => {
                console.log(`Documents updated from firebase for startup ${startup?.name} and record ${investmentRecord.uid}: ${newDocs.length}`)
                setInvestmentRecordData(prevData => ({ ...prevData, documents: newDocs }))
            }, 3)
            return () => {

                console.log(`DASH UNSUBSCRIBE from documents  for startup ${startup?.name} and record ${investmentRecord.uid}`)
                unsubscribe()
            }

        }

    }, [investmentRecord])
    const cardExpandClick = () => {
        if (investmentRecord?.commentary && investmentRecord?.commentary.length > 0) {
            setExpanded(!expanded)
        }
    }

    const cardEditClick = () => {
        navigation.navigate('StartupScreens', {
            screen: 'CreateInvestmentRecord', params: {
                startupUid: startup.uid, previousRecordId: investmentRecord?.uid
            }
        })
    }

    const latestDocument = (): Document | undefined => {
        const docs = investmentRecordData.documents

        return docs && docs.length > 0 ? docs[0] : undefined
    }

    const cardBackgroundColor = () => {
        if (!investmentRecord) {
            return 'orange'
        }
        switch (investmentRecord!.status) {
            case 'red':
                return 'red'
            case 'amber':
                return 'orange'
            case 'green':
                return 'green'
            case 'grey':
            default:
                return 'grey'
        }
    }

    const cardDateDisplay = () => {
        return investmentRecord?.recordDate?.toFormat('dd/LLL/yyyy') || 'no record'
    }

    const cardTextColor = () => {
        const toColor = cardBackgroundColor() === 'orange' ? 'black' : 'white'
        return toColor
    }

    const formatCommentary = (record: InvestmentRecord) => {
        if (!record) {
            return ""
        }
        return `(${record.lastModified.toISODate()}) ${record.commentary}`

    }

    const highlightIfRecent = (record: InvestmentRecord, doShorten: boolean) => {
        if (Math.floor(DateTime.now().diff(record.lastModified).as('days')) < 3) {
            return <>[NEW!] {doShorten ? shorten(formatCommentary(record), true) : formatCommentary(record)} </>
        } else {
            return doShorten ? shorten(formatCommentary(record)) : formatCommentary(record)
        }
    }
    const shorten = (input: string, recent: boolean = false) => {
        if (!input) {
            return ""
        }
        let limit = size === 'large' ? 75 : 60
        if (recent) {
            limit = limit - 6
        }
        if (input.length <= limit) {
            return input
        }
        return input.substring(0, limit) + '..'
    }

    const weekdaysBetween = (start: DateTime, end: DateTime) => {

        let weekdays: number
        if (end.weekNumber == start.weekNumber) {

            weekdays = Math.floor(end.diff(start).as('hours') / 24);
            if (end.weekday == 7 && start.weekday <= 6) {
                weekdays = weekdays - 1
            }
            if (end.weekday == 6 && start.weekday <= 5) {
                weekdays = weekdays - 1
            }
            if (weekdays < 0) {
                weekdays = 0;
            }

        } else {
            var first = DateTime.fromMillis(start.toMillis()).endOf('week'); // end of first week
            var last = DateTime.fromMillis(end.toMillis()).startOf('week'); // start of last week

            var weeks = Math.floor(last.diff(first).as('hours') / 24 / 7)
            var days = weeks * 5; // this will always multiply of 7
            var wfirst = 7 - start.weekday; // check first week
            if (start.weekday === 6) wfirst = wfirst - 1; // -1 if start with saturday 
            if ((start.hour > end.hour) || (start.hour == end.hour && start.minute > end.minute)) {
                wfirst = wfirst - 1;
            }
            var wlast = end.weekday - 1; // check last week
            if (end.weekday == 7) wlast = wlast - 1; // -1 if end with sunday
            weekdays = wfirst + Math.floor(days) + wlast; // get the total
            if (weekdays < 0) {
                weekdays = 0;
            }

        }

        // console.log(`Weekdays from ${start.toISO()} to ${end.toISO()} is ${weekdays} start:${start.weekday} end: ${end.weekday}`)

        return weekdays


    }
    const getBorderWidth = () => {

        if (investmentRecord && weekdaysBetween(investmentRecord.lastModified, DateTime.now()) < highlightDuration.days) {
            return 3;
        } else {
            return 0
        }
    }

    const getBorderColor = () => {

        const background = cardBackgroundColor()
        if (background === 'orange' || background === 'red') {
            return 'black'
        } else {
            return 'orange'
        }
    }

    const getCardWidth = () => {
        if (size === 'large') {
            return 400
        }

        return 300
    }
    const commentFontSize = size === 'large' ? 16 : 14
    return (

        <div key={'investment_card_div_' + startup.uid} style={{ paddingBottom: 10, marginRight: 10, marginLeft: 10 }}>

            <Card
                style={{ width: getCardWidth(), height: expanded ? '100%' : 130, backgroundColor: cardBackgroundColor(), borderWidth: getBorderWidth(), borderColor: getBorderColor() }}
                mode="elevated"
                onPress={cardExpandClick}
            >
                <Card.Title
                    titleStyle={{ color: cardTextColor(), fontSize: 15, minHeight: 34 }}
                    subtitleStyle={{ color: cardTextColor(), fontSize: 15, minHeight: 34 }}
                    title={

                        <div style={{ verticalAlign: 'middle', width: '100%' }}>
                            <div style={{ float: 'left', verticalAlign: 'middle' }}>
                                <FontAwesome
                                    name={'money'}
                                    size={22}
                                    color={cardTextColor()}
                                    style={{ marginRight: 15 }}
                                />
                                <Caption style={{ fontSize: 18, color: cardTextColor() }}><b>{'Investment'}</b></Caption>
                            </div>
                            <div style={{
                                float: 'right',
                                verticalAlign: 'bottom'
                            }}>

                                <FontAwesome
                                    style={{ padding: 3 }}
                                    name={"edit"}
                                    size={16}
                                    onPress={cardEditClick}
                                    color={cardTextColor()}
                                />
                                {
                                    investmentRecord && latestDocument() && (
                                        <Tooltip content={latestDocument()?.title} >
                                            <FontAwesome
                                                style={{ padding: 3 }}
                                                name={"paperclip"}
                                                size={16}
                                                onPress={() => Linking.openURL(latestDocument()?.url!)}
                                                color={cardTextColor()}
                                            />

                                        </Tooltip>
                                    )
                                }

                            </div>

                        </div>

                    }
                    subtitle={
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
                                <div>

                                    {

                                        cardDateDisplay()


                                    }
                                </div >
                                <div>
                                    {investmentRecord &&

                                        <>Valuation: <b>£{investmentRecord?.currentValuation}K</b></>

                                    }


                                </div >

                            </div >
                            <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
                                <div>
                                    {investmentRecord &&

                                        <>Shareholding: <b>{investmentRecord?.shareholdingPercentage}%</b></>


                                    }

                                </div >
                                <div>
                                    {investmentRecord &&

                                        <>TSF Share Value: <b>£{investmentRecord?.currentValuation * investmentRecord?.shareholdingPercentage / 100}K (£{investmentRecord?.tsfCost}K)</b></>
                                    }

                                </div >

                            </div >
                            {investmentRecord && investmentRecord.commentary && investmentRecord.commentary.length > 0 && expanded && (
                                <div style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
                                    <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />
                                    <div key={'comment0'} style={{ whiteSpace: 'pre-wrap', fontSize: commentFontSize }}>

                                        {highlightIfRecent(investmentRecord, false)}
                                    </div>

                                    <div style={{ float: 'right', fontSize: commentFontSize }}>
                                        <FontAwesome
                                            name='angle-double-up'
                                            onPress={cardExpandClick}
                                            size={18}
                                            color={cardTextColor()}
                                            style={{ marginHorizontal: 15 }}
                                        />
                                    </div>

                                </div>
                            )
                            }
                            {investmentRecord && investmentRecord.commentary && investmentRecord.commentary.length > 0 && !expanded && (
                                <div style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
                                    <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />
                                    <div key={'comment-not-exp-0'} style={{ whiteSpace: 'pre-line', fontSize: commentFontSize }}>
                                        {highlightIfRecent(investmentRecord, true)}
                                        <div key={'unexp-comment-div'} style={{ float: 'right' }}><FontAwesome
                                            name='angle-double-down'
                                            onPress={cardExpandClick}
                                            size={18}
                                            color={cardTextColor()}
                                            style={{ marginHorizontal: 15 }}
                                        /></div>

                                    </div>

                                </div>
                            )
                            }


                        </>
                    }
                />
            </Card >
            <div style={{ textAlign: 'center' }}>
                {investmentRecord && <Caption> {formatDuration(calculateDuration(DateTime.now(), investmentRecord.recordDate))}</Caption>}
            </div>
        </div >

    )
}

export default InvestmentTimeLineCard