const DarkBlue = '#0064ff'
const DarkGrey = '#202532'
const LightBlue = '#e2ecff'
const MediumBlue = '#a9cafc'

export default {
  Primary: DarkBlue,
  Secondary: MediumBlue,
  Tertiary: LightBlue,
  Success: LightBlue,
  Background: 'white',
  DarkBackgroundText: DarkGrey,
  Link: DarkBlue,
  TextEntryBorder: '#ddd',
}
