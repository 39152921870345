import React from 'react'
import { useNavigation} from '@react-navigation/native'
import { Pressable } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'

export type EditEventIconProps = {
    startupUid: string,
    eventUid: string,
}

const EditEventIcon: React.FC<EditEventIconProps> = ({ startupUid, eventUid }) => {

    const navigation = useNavigation()

    const onIconPress = () => {
        navigation.navigate('StartupScreens', {
                screen: 'EventScreens', params: {
                    screen: 'EditEvent', params: {
                        eventUid,
                        startupUid
                    }
                }
            })
       
    } 

    return (
        <Pressable
            onPress={onIconPress}
            style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                paddingLeft: 10,
            })}>
            <FontAwesome
                name="pencil"
                size={25}
                color={'black'}
                style={{ marginRight: 10 }}
            />
        </Pressable>
    )
}

export default EditEventIcon