/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */
import * as T from 'io-ts';
import { LuxonDateTimeT } from './LuxonTimestampCodec'

export type Phase = 'early-pipeline' | 'in-progress' | 'investment' | 'non-commercial' | 'archived' | 'incubator'
export type InvestmentStatus = 'green' | 'amber' | 'red' | 'grey'

export const leadSources = {
  website: 'TSF website',
  event: 'Event',
  partnership: 'Partnership',
  founderReferral: 'Founder referral',
  investorReferral: 'Investor referral',
  guyEHE: 'Guy/EHE',
  ianNetwork: 'Ian\'s network',
  walkIn: 'Walk-in',
  internal: 'Internal',
}
export const LeadSourceT = T.keyof(leadSources)
export type LeadSource = T.TypeOf<typeof LeadSourceT>

export const UserT = T.type({
  uid: T.string,
  email: T.string,
  name: T.string,
})

export type User = T.TypeOf<typeof UserT>

export const stages = {
  lead: 'Lead',
  prospect: 'Prospect',
  pitch: 'Pitch',
  scopingSession: 'Scoping Session',
  proposalPrep: 'Proposal Prep',
  commercialNegotiation: 'Commercial Negotiation',
  POCBuild: 'POC Build',
  POCTesting: 'POC Testing',
  MVPBuild: 'MVP Build',
  MVPTesting: 'MVP Testing',
  exit: 'Exit',
}
export const StageT = T.keyof(stages)
export type Stage = T.TypeOf<typeof StageT>

export const StartUpT = T.intersection(
  [
    T.type({
      uid: T.string,
      name: T.string,
      dateCreated: LuxonDateTimeT,
      lastModified: LuxonDateTimeT,
      founder: T.string,
      description: T.string,
      assignees: T.array(T.string),
      order: T.number,
    }),
    T.partial({
      lastEventCompleted: T.string,
      imageUri: T.string,
      url: T.string,
      archived: T.boolean,
      nonCommercial: T.boolean,
      investment: T.boolean,
      inProgress: T.boolean,
      earlyPipeline: T.boolean,
      incubator: T.boolean,
      leadSource: LeadSourceT,
      dateOfInvestment: LuxonDateTimeT,
      contact: T.string
    })
  ]
)
export type StartUp = T.TypeOf<typeof StartUpT>

export const EventT = T.intersection([
  T.type({
    uid: T.string,
    startupUid: T.string,
    title: T.string,
    stage: StageT,
    lastModified: LuxonDateTimeT,
    modifiedBy: T.string,
    indexOrder: T.number,
  }),
  T.partial({
    startDate: LuxonDateTimeT,
    expectedEndDate: LuxonDateTimeT,
    expectedNextUpdateDate: LuxonDateTimeT,
    endDate: LuxonDateTimeT,
    statusMessage: T.string,
    projectTrackingUrl: T.string,
    source: T.string
  })
])

export type Event = T.TypeOf<typeof EventT>

export const DocumentT = T.intersection([
  T.type({
    uid: T.string,
    startupUid: T.string,
    dateCreated: LuxonDateTimeT,
    url: T.string,
    title: T.string,
    lastUpdated: LuxonDateTimeT,
    // event?
  }),
  T.partial({
    eventUid: T.string,
    investmentRecordUid: T.string,
  })
])
export type Document = T.TypeOf<typeof DocumentT>

export const CommentT = T.type({
  uid: T.string,
  eventUid: T.string,
  startupUid: T.string,
  dateCreated: LuxonDateTimeT,
  comment: T.string,
  user: T.string,
})
export type Comment = T.TypeOf<typeof CommentT>

export const InvestmentRecordT = T.intersection([
  T.type({
    uid: T.string,
    startupUid: T.string,
    recordDate: LuxonDateTimeT,
    lastModified: LuxonDateTimeT,
    modifiedBy: T.string,
    currentValuation: T.number,
    tsfInvestmentValue: T.number,
    tsfCost: T.number,
    shareholdingPercentage: T.number,
    status: T.string
  }),
  T.partial({
    commentary: T.string
  })
])

export type InvestmentRecord = T.TypeOf<typeof InvestmentRecordT>
