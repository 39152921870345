import React, { useEffect, useState } from "react"
import { ProjectHealthRecord, TodoRecord, getProjectHealthChecks, getProjectTodos } from "../firestore/healthCheck"
import { View } from "react-native"
import LintCheckGraph from "../components/graphs/NxHealth/LintCheckGraph";
import TodoCheckGraph from "../components/graphs/NxHealth/TodoGraph";
import { Tabs, TabScreen } from 'react-native-paper-tabs';

const HealthCheck: React.FC = () => {

  const [lintChecks, setLintChecks] = useState<Record<string, ProjectHealthRecord>>({});
  const [todos, setTodos] = useState<TodoRecord>({})

  useEffect(() => {
    void (async () => {
      const lintCheckProjects = await getProjectHealthChecks()
      const todoCheck = await getProjectTodos()
      setLintChecks(lintCheckProjects)
      setTodos(todoCheck)
    })()
  },[])

  return (
    <View style={{ height: '100%' }}>
      <Tabs style={{ backgroundColor: 'white' }}>
      <TabScreen label="Lint Health" >
        <LintCheckGraph projects={lintChecks} />
      </TabScreen>
      <TabScreen label="To Dos">
        <TodoCheckGraph todos={todos} />
      </TabScreen>
      </Tabs>
    </View>
  )
}

export default HealthCheck