import React, { useState, useEffect } from 'react'
import {
  View
} from 'react-native'
import { Caption, Snackbar, Title } from 'react-native-paper';
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import type { Asserts } from 'yup'
import styles from '../../styles/generic.styles'
import { v4 as uuidv4 } from 'uuid';
import { setStartupInFirestore } from '../../firestore_sync'
import UploadImage from '../../components/forms/UploadImage'
import TextInputValidated from '../../components/forms/TextInputValidated'
import { leadSources, StartUp } from '../../types'
import { DateTime } from 'luxon'
import SelectAssigneesDropdown from '../../components/forms/SelectAssigneesDropdown'
import Loading from '../../components/Loading'
import DatePicker from '../../components/forms/DatePicker'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import DropDown from "react-native-paper-dropdown";
import { auth } from '../../FirebaseSingleton';
import { SubmitListener } from './SubmitListener';
import useGlobalContext from '../../context/GlobalContext';
import useStartupUidContext from '../../context/route/StartupUidContext';
import { formatObjectForDropdown } from "../../util/DropdownListFormat";


export type StartupFormProps = {
  startupUid?: string;
  title?: string;
}

const StartupFormSchema = Yup.object({
  name: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
  founder: Yup.string().trim().required('Required'),
  order: Yup.number().required('Required'),
  url: Yup.string().trim().url(),
  leadSource: Yup.string().trim().required('Required'),
})

type StartupFormEntry = Asserts<typeof StartupFormSchema>

const StartupForm: React.FC<StartupFormProps> = ({ startupUid }) => {
  const { setStartupUid } = useStartupUidContext()

  useEffect(() => {
    setStartupUid(startupUid)
  }, [])
  const [isNew, setIsNew] = useState(!startupUid)

  const [notifyText, setNotifyText] = useState('')

  const [loading, setLoading] = useState(true)
  const [leadDropdownOpen, setLeadDropdownOpen] = useState(false)
  const { data } = useGlobalContext()
  const users = data.users
  const [error, setError] = useState(false);


  const [startup, setStartup] = useState<StartUp>({
    uid: uuidv4(),
    name: '',
    dateCreated: DateTime.now().toUTC(),
    lastModified: DateTime.now().toUTC(),
    founder: '',
    url: '',
    description: '',
    assignees: [],
    order: 0,
    archived: false,
    investment: false,
    inProgress: false,
    earlyPipeline: true,
    nonCommercial: false,
    incubator: false,
    leadSource: undefined,
    contact: '',
    imageUri: undefined
  })

  useEffect(() => {
    setLoading(true)
    if (startupUid) {

      const foundStartup = data.startups.find(s => s.uid === startupUid)

      if (foundStartup) {
        setStartup(foundStartup);
        setError(false)
      } else {
        setError(true)
      }

    }
    setLoading(false)
  }, [startupUid, data])

  const handleSubmit = async (
    values: StartUp,
    formikActions: FormikHelpers<StartUp>,
  ) => {
    const cleanValues: StartupFormEntry = StartupFormSchema.validateSync(
      values,
    )
    try {

      if (!cleanValues) {
        throw new Error('Failed to validate startup details')
      }
      if (!auth.currentUser) {
        throw new Error('Not authorized')
      }
      const newStartup = {
        ...startup,
        name: cleanValues.name,
        description: cleanValues.description,
        lastModified: DateTime.now().toUTC(),
        modifiedBy: auth.currentUser.uid,
        founder: cleanValues.founder,
        order: cleanValues.order,
        url: cleanValues.url,
        dateCreated: values.dateCreated || DateTime.now(),
        earlyPipeline: values.earlyPipeline !== undefined ? values.earlyPipeline : false,
        inProgress: values.inProgress !== undefined ? values.inProgress : false,
        investment: values.investment !== undefined ? values.investment : false,
        nonCommercial: values.nonCommercial !== undefined ? values.nonCommercial : false,
        archived: values.archived !== undefined ? values.archived : false,
        incubator: values.incubator !== undefined ? values.incubator : false,
        leadSource: values.leadSource,
        imageUri: values.imageUri,
        dateOfInvestment: values.dateOfInvestment ? values.dateOfInvestment : undefined,
        contact: values.contact,
        assignees: values.assignees
      }
      const newStartupWithoutUndefinedFields = Object.create(newStartup)
      for (const [key, value] of Object.entries(newStartup)) {
        if (value !== undefined) {
          newStartupWithoutUndefinedFields[key] = value;
        }
      }
      await setStartupInFirestore(
        newStartupWithoutUndefinedFields,
        isNew,
      )
      setIsNew(false)
      setNotifyText('Changes saved successfully')

    } catch (error) {
      console.error(error)
      setNotifyText('Error saving data, please try again later. If the problem persists contact support')
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  if (loading) {
    return (<Loading />)
  }

  if (error) {
    return (
      <View style={styles.screenContainer}>
        <Caption style={styles.error}>Error, could not find startup with id</Caption>
      </View>
    )
  }

  return (
    <><Snackbar
      visible={(notifyText && notifyText !== '') ? true : false}
      onDismiss={() => setNotifyText('')}
      duration={notifyText && notifyText.toLowerCase().includes('error') ? 300000 : 5000}
    >
      {notifyText}
    </Snackbar>
      <View style={styles.screenContainer}>
        <Formik
          initialValues={startup}
          validationSchema={StartupFormSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <View>
              <SubmitListener formikProps={props} />
              <div style={{ float: 'left' }}>
                <div style={{ width: 600, float: 'left' }}>
                  <TextInputValidated
                    onChangeText={props.handleChange('name')}
                    onBlur={props.handleBlur('name')}
                    value={props.values.name}
                    placeholder={'Type here'}
                    label={'Startup Name'}
                    touched={props.touched.name}
                    error={props.errors.name}
                  />
                  <TextInputValidated
                    onChangeText={props.handleChange('description')}
                    onBlur={props.handleBlur('description')}
                    value={props.values.description}
                    placeholder={'Type here'}
                    label={'Description'}
                    touched={props.touched.description}
                    error={props.errors.description}
                  />
                  <TextInputValidated
                    onChangeText={props.handleChange('founder')}
                    onBlur={props.handleBlur('founder')}
                    value={props.values.founder}
                    placeholder={'Type here'}
                    label={'Founder(s)'}
                    touched={props.touched.founder}
                    error={props.errors.founder}
                  />
                  <TextInputValidated
                    onChangeText={props.handleChange('url')}
                    onBlur={props.handleBlur('url')}
                    value={props.values.url || ''}
                    placeholder={'Type here'}
                    label={'Website'}
                    touched={props.touched.url}
                    error={props.errors.url}
                  />
                  <TextInputValidated
                    multiLine={true}
                    onChangeText={props.handleChange('contact')}
                    onBlur={props.handleBlur('contact')}
                    value={props.values.contact || ''}
                    placeholder={'Type here - enter for new line'}
                    label={'Contact Details'}
                    touched={props.touched.contact}
                    error={props.errors.contact}
                  />

                </div>
                <div style={{ width: 600, float: 'left' }}>
                  <SelectAssigneesDropdown
                    users={users}
                    selectedAssignees={props.values.assignees.toString()}
                    setSelectedAssignees={(selectedAssignees) => {
                      props.setFieldValue('assignees', selectedAssignees.split(','))

                    }}
                  />

                  <UploadImage
                    imageUriCallback={(imageUri) => {
                      props.setFieldValue('imageUri', imageUri)
                    }}
                    prevImageUri={props.values.imageUri}
                  />

                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('earlyPipeline', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('archived', false)
                        props.setFieldValue('investment', false)
                      }
                    }}
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.earlyPipeline || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>Early pipeline</Title>
                    }
                  />


                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('inProgress', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('archived', false)
                      }
                    }}
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.inProgress || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>In progress</Title>
                    }
                  />

                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('investment', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('archived', false)
                      }
                    }}
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.investment || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>In investment</Title>
                    }
                  />
                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('incubator', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('archived', false)
                        props.setFieldValue('investment', false)
                        props.setFieldValue('earlyPipeline', false)
                        props.setFieldValue('inProgress', false)
                      }
                    }}
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.incubator || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>Incubator</Title>
                    }
                  />

                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('nonCommercial', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('archived', false)
                        props.setFieldValue('investment', false)
                      }
                    }}
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.nonCommercial || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>Non-commercial</Title>
                    }
                  />

                  <BouncyCheckbox
                    onPress={(isChecked: boolean) => {
                      props.setFieldValue('archived', isChecked)
                      if (isChecked === true) {
                        props.setFieldValue('investment', false)
                        props.setFieldValue('inProgress', false)
                        props.setFieldValue('nonCommercial', false)
                        props.setFieldValue('earlyPipeline', false)
                      }
                    }
                    }
                    fillColor='black'
                    iconStyle={{ borderColor: 'black' }}
                    style={{ paddingBottom: 10 }}
                    isChecked={props.values.archived || false}
                    textComponent={
                      <Title style={{ paddingLeft: 10 }}>Archived</Title>
                    }
                  />

                  <DatePicker
                    setToNullAllowed={false}
                    label={`Date Created`}
                    setterFunction={(value: DateTime | undefined) => {
                      props.setFieldValue('startDate', value)
                    }}
                    selectedDate={props.values.dateCreated}
                  />
                  <DatePicker
                    setToNullAllowed={false}
                    label={`Date of Investment`}
                    setterFunction={(value: DateTime | undefined) => {
                      props.setFieldValue('dateOfInvestment', value)
                    }}
                    selectedDate={props.values.dateOfInvestment}
                  />

                  <div style={{ width: '80%' }}>
                    <DropDown
                      label={"Lead source"}
                      mode={"outlined"}

                      visible={leadDropdownOpen}
                      showDropDown={() => setLeadDropdownOpen(true)}
                      onDismiss={() => setLeadDropdownOpen(false)}
                      value={props.values.leadSource}
                      setValue={(value) => {
                        props.setFieldValue('leadSource', value)
                      }}

                      list={formatObjectForDropdown(leadSources)}
                    />
                    {(props.errors.leadSource) && (
                      <Caption style={{ color: 'red' }}>
                        {props.errors.leadSource}
                      </Caption>
                    )}
                  </div>
                </div>
              </div>
            </View>
          )}
        </Formik>
      </View>
    </>
  )
}

export default StartupForm
