import React from 'react'
import { View, Linking } from 'react-native'

import styles from '../../styles/generic.styles'
import { Document } from '../../types'
import { Paragraph, Title } from 'react-native-paper';
import useStartupContext from '../../context/StartupContext';

export type DocumentLibraryProps = {
    startupUid: string
}

const DocumentLibrary: React.FC<DocumentLibraryProps> = ({ startupUid }) => {

    const { data } = useStartupContext()

    const documentOnClick = (doc: Document) => {
        Linking.openURL(doc.url)
    }

    return (
        <View>
            {(data.documents) && (
                <>
                    <Title style={styles.heading}>Document Library:</Title>
                    {data.documents?.map((doc) => (
                        <Paragraph key={'doc_para_' + doc.uid} onPress={() => documentOnClick(doc)}>
                            {doc.title} - {doc.url}
                        </Paragraph>
                    ))}

                </>
            )}

        </View>
    )
}

export default DocumentLibrary