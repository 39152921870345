import { View } from "react-native"
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts"
import { TodoRecord } from "../../../firestore/healthCheck"
import { DateTime } from "luxon"
import { formatDateString, stringToColour } from "./util"
import { TodoGraphLabel } from "./HealthGraphLabel"
import { useMemo, useState } from "react"
import { DateRange } from "./LintCheckGraph"
import { DateRangeControls } from "./DateRangeControls"

export type TodoGraphData = Record<string, string|number>[]

const formatTodos = (todos:TodoRecord): TodoGraphData => {
  const dateOrientated:TodoRecord = {}
  Object.entries(todos).map(([project, dates]) => 
    Object.entries(dates).map(([date, value]) => {
      const fixedMonthIndexDate = formatDateString(date)
      dateOrientated[fixedMonthIndexDate] = {
        ...dateOrientated[fixedMonthIndexDate],
        date: fixedMonthIndexDate,
        [project]: value 
      }
    })
  )
  const filtered = Object.values(dateOrientated).filter((a) => DateTime.fromFormat(a.date.toString(), 'yyyy-MM-dd').isValid)
  return Object.values(filtered).sort((a, b) => {
    const aTime = DateTime.fromJSDate(new Date(a.date))
    const bTime = DateTime.fromJSDate(new Date(b.date))
    return aTime.diff(bTime).as('days')
  })
}

type Props = {
    todos: TodoRecord
}
const TodoCheckGraph: React.FC<Props> = ({ todos }) => {
  const [dateRange, setDateRange] = useState<DateRange>('MONTH')
  
  const formattedData = useMemo(() => {
    const formatted =  formatTodos(todos)
    return dateRange === 'MONTH' ? formatted.slice(-30) : dateRange === 'YEAR' ? formatted.slice(-365) : formatted
  }, [todos, dateRange])

  return (
    <View style={{ width: 500, height: '100%'}}>
      <View style={{ width: 1200, height: 750 }}>
        <LineChart width={1200} height={750} data={formattedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Legend/>
        {Object.keys(todos).map((todo) => (
          <Line 
            key={todo}
            type="monotone" dataKey={todo}
            stroke={stringToColour(todo)}
            label={(props) => TodoGraphLabel(props, formattedData, todo) }
          />
        ))}
        </LineChart>
      </View>
      <DateRangeControls setDateRange={setDateRange} dateRange={dateRange}/>
    </View>
  )
}

export default TodoCheckGraph