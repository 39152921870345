import { compact } from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import {
  collection, query, getDocs, getDoc, doc, setDoc, deleteDoc, onSnapshot, updateDoc
} from 'firebase/firestore'
import { db, auth } from '../FirebaseSingleton'
import { decodeWith } from '../decodeHelper'
import { StartUp, StartUpT, Event, stages, Stage } from '../types'
import { DateTime } from 'luxon';
import { setEventInFirestore } from './event';
import { LuxonDateTimeT } from '../LuxonTimestampCodec';

export const setStartupInFirestore = async (startup: StartUp, isNew: boolean): Promise<void> => {
  if (auth.currentUser) {
    await setDoc(doc(db, "startups", startup.uid), StartUpT.encode(startup))
    // create base events here
    if (isNew) {
      for (let i = 0; i < Object.entries(stages).length; i++) {
        const newEvent: Event = {
          title: Object.entries(stages)[i][1],
          uid: uuidv4(),
          startupUid: startup.uid,
          stage: Object.entries(stages)[i][0] as Stage,
          lastModified: DateTime.now().toUTC(),
          modifiedBy: auth.currentUser?.uid,
          indexOrder: i
        }
        await setEventInFirestore(newEvent)
      }
    }
  }
  return new Promise((resolve) => resolve())
}

export const updateLastModified = async (startupUid: string) => {
  await updateDoc(doc(db, "startups", startupUid), {
    lastModified: LuxonDateTimeT.encode(DateTime.now().toUTC()),
    modifiedBy: auth.currentUser?.uid,
  })

}

export const setStartUpsInFirestore = async (startUps: StartUp[]): Promise<void> => {
  try {
    startUps.forEach(async (startup) => {
      await setDoc(doc(db, `startups`, startup.uid), StartUpT.encode(startup))
    })
    return new Promise((resolve) => resolve())
  } catch (err) {
    console.error(err)
  }
}

export const deleteStartUpInFirestore = async (startUp: StartUp): Promise<void> => {
  try {
    await deleteDoc(doc(db, `/startups`, startUp.uid))
    return new Promise((resolve) => resolve())
  } catch (err) {
    console.error(err)
  }
}

export const getStartupsListener = (callback: (startups: StartUp[]) => void) => {

  const q = query(collection(db, '/startups'))
  const unsub = onSnapshot(q, (snapshot) => {
    const { docs } = snapshot
    const startups: StartUp[] = compact(docs.map(
      (doc: { data: () => unknown }) => decodeWith(StartUpT, doc.data()),
    ))
    callback(startups)
  })
  return unsub

}

export const getAllStartUpsInFirestore = async (): Promise<StartUp[] | undefined> => {
  try {
    const snap = await getDocs(query(collection(db, `/startups`)))
    const { docs } = snap
    return compact(docs.map((doc) => decodeWith(StartUpT, doc.data())))
  } catch (err) {
    console.error(err)
  }
}

export const getStartupInFirestore = async (uid: string): Promise<StartUp | undefined> => {
  try {
    const snap = await getDoc(doc(db, `/startups`, uid))
    if (!snap.exists) {
      throw new Error('Failed to find the startup in Firestore')
    }
    const startup = decodeWith(StartUpT, snap.data())
    return startup
  } catch (err) {
    console.error(err)
  }
}