import { InvestmentRecord } from "../../types"
import React, { useState, useEffect } from 'react'
import { setInvestmentRecordInFirstore } from "../../firestore_sync"
import { View } from 'react-native'
import { Caption, Snackbar } from 'react-native-paper';
import * as Yup from 'yup'
import type { Asserts } from 'yup'
import styles from "../../styles/generic.styles";
import { Formik, FormikHelpers } from 'formik'
import TextInputValidated from '../../components/forms/TextInputValidated'
import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';
import DropDown from "react-native-paper-dropdown";
import { auth } from "../../FirebaseSingleton"
import Loading from "../../components/Loading"
import DatePicker from "../../components/forms/DatePicker"
import { isMobile } from "../../util/IsMobile"
import { updateLastModified as updateLastModifiedStartup } from '../../firestore/startups';
import { useNavigation } from "@react-navigation/native";
import ActionButton from "../../components/ActionButton";
import useStartupContext from '../../context/StartupContext';
import useStartupUidContext from "../../context/route/StartupUidContext";

export type InvestmentRecordFormProps = {
  startupUid: string;
  previousRecordId?: string;
}

const InvestmentRecordFormSchema = Yup.object({
  shareholdingPercentage: Yup.number().required('Required'),
  currentValuation: Yup.number().required('Required'),
  tsfCost: Yup.number().required('Required'),
  tsfInvestmentValue: Yup.number().required('Required'),
  status: Yup.string().trim().required('Required'),
  recordDate: Yup.date().required('Required')

})

type InvestmentRecordFormEntry = Asserts<typeof InvestmentRecordFormSchema>

const InvestmentRecordForm: React.FC<InvestmentRecordFormProps> = ({ startupUid, previousRecordId }) => {
  const { setStartupUid } = useStartupUidContext()

  useEffect(() => {
    setStartupUid(startupUid)
  }, [])


  const { data } = useStartupContext()

  const [record, setRecord] = useState<InvestmentRecord>()
  const [loading, setLoading] = useState(true)
  const [notifyText, setNotifyText] = useState('')

  const [error, setError] = useState(false);
  const [openStatusPicker, setOpenStatusPicker] = useState(false)

  const itemsStage =
    [
      {
        label: 'grey',
        value: 'grey',
      },
      {
        label: 'green',
        value: 'green',
      },
      {
        label: 'amber',
        value: 'amber',
      },
      {
        label: 'red',
        value: 'red',
      }
    ]

  const handleSubmit = async (
    values: InvestmentRecord,
    formikActions: FormikHelpers<InvestmentRecord>) => {
    const cleanValues: InvestmentRecordFormEntry = InvestmentRecordFormSchema.validateSync(values)
    try {

      if (!cleanValues) {
        throw new Error('Failed to validate event details')
      }
      if (startupUid && auth.currentUser) {

        const newRecord = {
          uid: values.uid,
          startupUid: startupUid,
          currentValuation: cleanValues.currentValuation,
          shareholdingPercentage: cleanValues.shareholdingPercentage,
          tsfCost: cleanValues.tsfCost,
          tsfInvestmentValue: cleanValues.tsfInvestmentValue,
          status: cleanValues.status,
          commentary: values.commentary,
          recordDate: values.recordDate,
          lastModified: DateTime.now().toUTC(),
          modifiedBy: auth.currentUser.uid
        }
        const newRecordWithoutUndefinedFields = Object.create(newRecord)
        for (const [key, value] of Object.entries(newRecord)) {
          if (value !== undefined) {
            newRecordWithoutUndefinedFields[key] = value;
          }
        }


        try {
          await setInvestmentRecordInFirstore(newRecordWithoutUndefinedFields)
          setNotifyText('Changes saved successfully')
        } catch (e) {
          console.error(e)
          setNotifyText('Error saving data, please try again later. If the problem persists contact support')
        }
        await updateLastModifiedStartup(startupUid)



      }
    } catch (err) {
      console.error(err)
      setNotifyText('Unexpected error. If the problem persists contact support')
    }
  }

  useEffect(() => {
    setLoading(true)
    if (previousRecordId) {

      const records = data.investmentRecords
      if (records && records.length > 0) {
        console.log('updating record data')
        setRecord((prevData) => ({
          ...records[0],
          uid: prevData ? prevData.uid : uuidv4(),
          recordDate: DateTime.now().toUTC()
        }))
        setError(false)
      } else {
        setError(true)
      }

    } else {
      setRecord({
        uid: uuidv4(),
        startupUid,
        currentValuation: 0,
        shareholdingPercentage: 0,
        tsfCost: 0,
        tsfInvestmentValue: 0,
        lastModified: DateTime.now().toUTC(),
        modifiedBy: auth.currentUser?.uid!,
        recordDate: DateTime.now().toUTC(),
        status: 'grey',
        commentary: '',
      })
    }
    setLoading(false)
  }, [startupUid, previousRecordId, data])


  if (loading || !record) {
    return (<Loading />)
  }
  if (error) {
    return (
      <View style={styles.screenContainer}>
        <Caption style={styles.error}>Error</Caption>
      </View>
    )
  }
  return (<>
    <Snackbar
      visible={(notifyText && notifyText !== '') ? true : false}
      onDismiss={() => setNotifyText('')}
      duration={notifyText && notifyText.toLowerCase().includes('error') ? 300000 : 5000}
      style={{ backgroundColor: (notifyText && notifyText.toLowerCase().includes('error')) ? 'red' : 'black' }}
    >
      {notifyText}
    </Snackbar>

    <View style={styles.formContainer}>
      <Formik
        initialValues={record}
        validationSchema={InvestmentRecordFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {

          return (
            <View>



              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <TextInputValidated
                  onChangeText={props.handleChange('currentValuation')}
                  onBlur={props.handleBlur('currentValuation')}
                  value={props.values.currentValuation.toString()}
                  placeholder={"Type here"}
                  label={"Current valuation (£000's)"}
                  touched={props.touched.currentValuation}
                  error={props.errors.currentValuation}
                />
                <TextInputValidated
                  onChangeText={props.handleChange('shareholdingPercentage')}
                  onBlur={props.handleBlur('shareholdingPercentage')}
                  value={props.values.shareholdingPercentage.toString()}
                  placeholder={"Type here"}
                  label={"Shareholding percentage"}
                  touched={props.touched.shareholdingPercentage}
                  error={props.errors.shareholdingPercentage}
                />
                <TextInputValidated
                  onChangeText={props.handleChange('tsfInvestmentValue')}
                  onBlur={props.handleBlur('tsfInvestmentValue')}
                  value={props.values.tsfInvestmentValue.toString()}
                  placeholder={"Type here"}
                  label={"TSF Intitial Equity Value (£000's)"}
                  touched={props.touched.tsfInvestmentValue}
                  error={props.errors.tsfInvestmentValue}
                />
                <TextInputValidated
                  onChangeText={props.handleChange('tsfCost')}
                  onBlur={props.handleBlur('tsfCost')}
                  value={props.values.tsfCost.toString()}
                  placeholder={"Type here"}
                  label={"TSF cost (£000's)"}
                  touched={props.touched.tsfCost}
                  error={props.errors.tsfCost}
                />
                <div style={{ width: isMobile() ? '80%' : '80%' }}>
                  <DropDown
                    label={"Status"}
                    mode={"outlined"}
                    visible={openStatusPicker}
                    showDropDown={() => setOpenStatusPicker(true)}
                    onDismiss={() => setOpenStatusPicker(false)}
                    value={props.values.status}
                    setValue={(value) => {
                      props.setFieldValue('status', value)
                    }}
                    list={itemsStage}
                  />
                </div>
                <DatePicker
                  setToNullAllowed={true}
                  label='Record date'

                  setterFunction={(value: DateTime | undefined) => {
                    props.setFieldValue('recordDate', value)
                  }
                  }
                  selectedDate={
                    props.values.recordDate
                  }
                />

                <TextInputValidated
                  multiLine={true}
                  onChangeText={props.handleChange('commentary')}
                  onBlur={props.handleBlur('commentary')}
                  value={props.values.commentary ? props.values.commentary : ''}
                  placeholder={"Type here (multiple lines)"}
                  label={"Commentary"}
                  touched={props.touched.commentary}
                  error={props.errors.commentary}
                />
                <ActionButton
                  onPress={() => {
                    const valuesEqualInitialValues =
                      props.values === props.initialValues;


                    console.log('Formik errors:' + JSON.stringify(props.errors))

                    if (!valuesEqualInitialValues) {
                      console.log('Submitting form...')

                      props.submitForm()
                    }
                  }}
                  disabled={props.values === props.initialValues}
                  style={props.values === props.initialValues ? {} : styles.activeButton}
                >
                  Add Investment Record
                </ActionButton>

              </div>
            </View>
          )
        }}
      </Formik>
    </View >
  </>
  )

}

export default InvestmentRecordForm
