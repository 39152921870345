import React, { useMemo, useState } from "react"
import { Tabs, TabScreen } from 'react-native-paper-tabs';
import { ProjectHealthRecord } from "../../../firestore/healthCheck"
import { View } from "react-native"
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts"
import { DateTime } from "luxon"
import { formatDateString, stringToColour } from "./util";
import { LintGraphLabel } from "./HealthGraphLabel";
import { DateRangeControls } from "./DateRangeControls";

type LintCheck = {
  warnings: number;
  fixable_warnings: number;
  errors: number;
}
export type LintGraphData = Record<string, LintCheck | string>[]
export type DataKey = 'warnings' | 'fixable_warnings' | 'errors'

const formatProjectHealth = (projects: Record<string, ProjectHealthRecord>): LintGraphData=> {
  const dateOrientated: Record<string, Record<string, LintCheck>> = {}
  Object.entries(projects).map(([projectName, dates]) => {
    Object.entries(dates).map(([date, health]) => {
      const fixedMonthIndexDate = formatDateString(date)
      dateOrientated[fixedMonthIndexDate] = {
        ...dateOrientated[fixedMonthIndexDate],
        [projectName]: {
          errors: health.errors,
          warnings: health.warnings,
          fixable_warnings: health.fixable_warnings
        }
      }
    })        
  })
  const formatted = Object.entries(dateOrientated).map(([date, project]) => ({
    date,
    ...project
  }))

  const filtered = formatted.filter((f) => DateTime.fromFormat(f.date, 'yyyy-MM-dd').isValid)

  const sorted = filtered.sort((a, b) => {
    const aTime = DateTime.fromFormat(a.date, 'yyyy-MM-dd')
    const bTime = DateTime.fromFormat(b.date, 'yyyy-MM-dd')
    return aTime.diff(bTime).as('seconds')
  })

  return sorted
}

const GenerateGraph = (projects: Record<string, ProjectHealthRecord>, dataKey: DataKey, dateRange: DateRange) => {
  const formattedData = useMemo(() => {
    const formatted = formatProjectHealth(projects)
    return dateRange === 'MONTH' ? formatted.slice(-30) : dateRange === 'YEAR' ? formatted.slice(-365) : formatted
  }, [projects, dateRange])


  return (
    <View style={{ width: 1200, height: 750 }}>
      <LineChart width={1200} height={750} data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Legend/>
      {Object.keys(projects).map((project) => (
        <Line 
          key={project}
          type="monotone"
          dataKey={`${project}.${dataKey}`}
          stroke={stringToColour(project)}
          label={(props) => LintGraphLabel(props, formattedData, project, dataKey)}
        />
      ))}
      </LineChart>
    </View>
  )
}

type Props = {
    projects: Record<string, ProjectHealthRecord>
}

export type DateRange = 'ALL' | 'YEAR' | 'MONTH'
const LintCheckGraph: React.FC<Props> = ({ projects }) => {
    const [dateRange, setDateRange] = useState<DateRange>('MONTH')

    return (
      <View >
        <Tabs style={{backgroundColor: 'white'}}>
          <TabScreen label="warnings">
            {GenerateGraph(projects, 'warnings', dateRange)}
          </TabScreen>
          <TabScreen label="Errors">
            {GenerateGraph(projects, 'errors', dateRange)}
          </TabScreen>
          <TabScreen label="fixable warnings">
            {GenerateGraph(projects, 'fixable_warnings', dateRange)}
          </TabScreen>
        </Tabs>

        <DateRangeControls setDateRange={setDateRange} dateRange={dateRange}/>
      </View>
    )
}

export default LintCheckGraph