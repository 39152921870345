import React, { FC, useState, useCallback } from 'react'
import { DatePickerModal } from 'react-native-paper-dates'
import ActionButton from '../ActionButton'
import styles from '../../styles/generic.styles'
import { FormikHandlers } from 'formik'
import { DateTime, DateTimeOptions } from 'luxon'
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar'
import { date } from 'yup'
import { StyleSheet } from 'react-native'

export type DatePickerProps = {
  label: string,
  setterFunction: FormikHandlers['handleChange'] | ((e: DateTime | undefined) => void)
  selectedDate: DateTime | undefined,
  setToNullAllowed: boolean
}

const DatePicker: FC<DatePickerProps> = ({ label, setterFunction, selectedDate, setToNullAllowed }) => {
  const [openPicker, setOpenPicker] = useState(false)
  const [valueLabel, setValueLabel] = useState(selectedDate ? selectedDate.toFormat('dd LLL yyyy') : '-')


  const onDismissSingle = useCallback(() => {
    setOpenPicker(false);
  }, [setOpenPicker]);

  const onConfirmSingle = useCallback(
    (params: {
      date: CalendarDate;
    }) => {
      setOpenPicker(false)
      if (params.date) {
        setValueLabel(DateTime.fromJSDate(params.date).toFormat('dd LLL yyyy'))
      } else {
        setValueLabel('-')
      }
      setterFunction(params.date ? DateTime.fromJSDate(params.date) : undefined)
    },
    [setOpenPicker, setterFunction]//todo review
  )

  return (
    <>
      {setToNullAllowed &&

        <div style={{ width: '80%', float: 'left' }}>
          <ActionButton
            onPress={() => setOpenPicker(true)}
            style={localStyles.actionButton}
          >
            {label}: {valueLabel}
          </ActionButton>
          <ActionButton
            onPress={() => {
              setterFunction(undefined)
              setValueLabel('-')
            }}
            style={localStyles.resetButton}
          >
            Remove
          </ActionButton>
          <DatePickerModal
            locale="eu"
            mode="single"
            saveLabel='Save and return'
            visible={openPicker}
            onDismiss={onDismissSingle}
            date={selectedDate ? selectedDate.toJSDate() : DateTime.now().toJSDate()}
            onConfirm={onConfirmSingle}
          />
        </div>}
      {!setToNullAllowed &&

        <>
          <ActionButton
            onPress={() => setOpenPicker(true)}
            style={styles.button}
          >
            {label}: {valueLabel}
          </ActionButton>

          <DatePickerModal
            locale="eu"
            mode="single"
            saveLabel='Set and return'
            visible={openPicker}
            onDismiss={onDismissSingle}
            date={selectedDate ? selectedDate.toJSDate() : DateTime.now().toJSDate()}
            onConfirm={onConfirmSingle}
          />
        </>}

    </>
  )
}

const localStyles = StyleSheet.create({
  actionButton: {
    width: '70%',
    float: 'left',
    marginTop: 10,
    marginBottom: 10,
  },
  resetButton: {
    width: '25%',
    float: 'right',
    marginTop: 10,
    marginBottom: 10,
  },

});


export default DatePicker