import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-native-paper';
import { StartupNavParams } from './NavigationTypes';
import { Document, Event, InvestmentRecord, StartUp } from '../types';
import StartupForm from '../screens/forms/StartupForm';
import StartUpDetails from '../screens/StartUpDetails';
import EventForm from '../screens/forms/EventForm';
import BackArrow from '../components/icons/BackArrow';
import EditStartupIcon from '../components/icons/EditStartupIcon';
import EditTimeline from '../screens/EditTimeline';
import { getEventsListener } from '../firestore_sync';
import InvestmentRecordForm from '../screens/forms/InvestmentRecordForm';
import useGlobalContext from '../context/GlobalContext';
import { StartupContext, StartupData } from '../context/StartupContext';
import { getLatestInvestmentRecordsListener } from '../firestore/investmentrecord';
import { getAllDocumentsByStartupListener } from '../firestore/document';
import { StartupUidContext } from '../context/route/StartupUidContext';
import EventNavigator from './EventNavigator';
import InvestmentRecordNavigator from './InvestmentRecordNavigator';

const StartupNavigator: React.FC = () => {
  const globalContext = useGlobalContext()
  const [startupUid, setStartupUid] = useState<string | undefined>()
  const [data, setData] = useState<StartupData>({
    events: [],
    documents: [],
    investmentRecords: []
  })


  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      startup: globalContext.data.startups.find(s => s.uid === startupUid),
      events: [],
      documents: [],
      investmentRecords: []
    }))
  }, [startupUid, globalContext.data.startups])

  useEffect(() => {
    if (data.startup && data.startup.uid === startupUid) {
      const unsubscribe = getEventsListener(data.startup.uid, (newEvents: Event[]) => {
        console.log(`Events updated from firebase for startup ${data.startup?.name}: ${newEvents.length}`)
        setData(prevData => ({ ...prevData, events: newEvents }))
      })
      return () => {
        console.log(`NAV UNSUBSCRIBE from events for startup ${data.startup?.name}`)
        unsubscribe()
      }
    }
  }, [data.startup, startupUid])

  useEffect(() => {
    if (data.startup && data.startup.uid === startupUid) {
      const unsubscribe = getLatestInvestmentRecordsListener(data.startup.uid, (newRecords: InvestmentRecord[]) => {
        console.log(`Investment records updated from firebase for startup ${data.startup?.name}: ${newRecords.length}`)
        setData(prevData => ({ ...prevData, investmentRecords: newRecords }))
      })
      return () => {
        console.log(`NAV UNSUBSCRIBE from investment records for startup ${data.startup?.name}`)
        unsubscribe()
      }
    }
  }, [data.startup, startupUid])

  useEffect(() => {
    if (data.startup && data.startup.uid !== startupUid) {
      const unsubscribe = getAllDocumentsByStartupListener(data.startup.uid, (newDocs: Document[]) => {
        console.log(`DOcuments updated from firebase for startup ${data.startup?.name}: ${newDocs.length}`)
        setData(prevData => ({ ...prevData, documents: newDocs }))
      })
      return () => {
        console.log(`NAV UNSUBSCRIBE from documents for startup ${data.startup?.name}`)
        unsubscribe()
      }
    }
  }, [data.startup, startupUid])

  const screenTitle = (prefix: string, suffix: string, altText: string) => {
    if (data.startup) {
      return `${prefix} ${data.startup.name} ${suffix}`
    } else {
      return `${altText}`
    }
  }
  return (
    <StartupUidContext.Provider value={{ startupUid, setStartupUid }}>
      <StartupContext.Provider value={{
        data
      }}>
        <StartupNavStack.Navigator>
          <StartupNavStack.Screen name="StartUpDetails"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', '', 'Startup')}</Title>,
              headerLeft: () => <BackArrow />,
              headerRight: () => <EditStartupIcon startupUid={route.params.startupUid} />
            })}>
            {({ route }) => {
              return (<>
                <StartUpDetails startupUid={route.params.startupUid} />
              </>)
            }
            }
          </StartupNavStack.Screen>

          <StartupNavStack.Screen name="EditStartup"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('Edit ', '', 'Create Startup')}</Title>,
              headerLeft: () => <BackArrow startupUid={startupUid} />
            })}
          >
            {({ route }) => {
              return (
                <>
                  <StartupForm startupUid={startupUid} />
                </>
              )
            }}
          </StartupNavStack.Screen>
          <StartupNavStack.Screen name="CreateEvent"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Event - Create', 'Event')}</Title>,
              headerLeft: () => <BackArrow startupUid={startupUid} />
            })}
          >
            {({ route }) => {
              return (
                <>
                  <EventForm
                    startupUid={route.params.startupUid}
                    eventUid={undefined}
                    indexOrder={route.params?.indexOrder}
                  />
                </>
              )
            }}
          </StartupNavStack.Screen>

          <StartupNavStack.Screen name="EditTimeline"
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Timeline - Edit', 'Edit Timeline')}</Title>,
              headerLeft: () => <BackArrow startupUid={startupUid} />
            })}>
            {({ route }) => {
              return (
                <>
                  <EditTimeline startupUid={route.params.startupUid} />
                </>)
            }}
          </StartupNavStack.Screen>


          <StartupNavStack.Screen name='CreateInvestmentRecord'
            options={({ route }) => ({
              headerTitle: () => <Title>{screenTitle('', 'Investment Record', '-')}</Title>,
              headerLeft: () => <BackArrow startupUid={startupUid} />
            })}
          >
            {({ route }) => {
              return (
                <>
                  <InvestmentRecordForm
                    startupUid={route.params.startupUid}
                    previousRecordId={route.params?.previousRecordId}
                  />
                </>
              )
            }}
          </StartupNavStack.Screen>
          <StartupNavStack.Screen name="EventScreens" options={{ headerShown: false }} >
            {({ route, navigation }) => {
              return <EventNavigator />
            }}
          </StartupNavStack.Screen>
          <StartupNavStack.Screen name="InvestmentRecordScreens" options={{ headerShown: false }} >
            {({ route, navigation }) => {
              return <InvestmentRecordNavigator />
            }}
          </StartupNavStack.Screen>
        </StartupNavStack.Navigator>
      </StartupContext.Provider>
    </StartupUidContext.Provider >
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const StartupNavStack = createNativeStackNavigator<StartupNavParams>();
export default StartupNavigator