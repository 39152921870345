import { Linking } from "react-native"
import { Document, Event, InvestmentRecord } from "../../types"
import React from 'react'
import styles from '../../styles/generic.styles'
import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { Paragraph } from 'react-native-paper';
import useStartupContext from "../../context/StartupContext";
import useEventContext from "../../context/EventContext";
import useInvestmentRecordContext from "../../context/InvestmentRecordContext";

export type DocumentListProps = {
    event?: Event
    investmentRecord?: InvestmentRecord
}

const DocumentList: React.FC<DocumentListProps> = ({ event, investmentRecord }) => {

    const navigation = useNavigation()
    const eventContext = useEventContext()
    const investmentRecordContext = useInvestmentRecordContext()



    const onDocPress = async (url: string) => {
        await Linking.canOpenURL(url) ? Linking.openURL(url) : console.error('can not open url')
    }

    const onEditPress = (doc: Document) => {
        if (doc.eventUid) {
            navigation.navigate('StartupScreens', {
                screen: 'EventScreens', params: {
                    screen: 'EditDocumentEvent', params: {
                        eventUid: doc.eventUid,
                        startupUid: doc.startupUid,
                        documentUid: doc.uid
                    }
                }
            })
        }
        if (doc.investmentRecordUid) {
            navigation.navigate('StartupScreens', {
                screen: 'InvestmentRecordScreens', params: {
                    screen: 'EditDocumentInvestment', params: {
                        recordUid: doc.investmentRecordUid,
                        startupUid: doc.startupUid,
                        documentUid: doc.uid
                    }
                }
            })
        }
    }

    return (
        <>
            {eventContext.data.documents?.map((doc) => (
                <div style={{ display: 'flex' }} key={doc.uid}>
                    <Paragraph
                        key={doc.uid}
                        onPress={() => onDocPress(doc.url)}
                        style={styles.normalText}
                    >
                        <b>{doc.title}</b>
                        {' '}- {doc.dateCreated.toFormat('dd LLL yyyy HH:mm')}
                    </Paragraph>
                    <FontAwesome
                        name="pencil"
                        onPress={() => onEditPress(doc)}
                        size={18}
                        color={'black'}
                        style={{ marginHorizontal: 15 }}
                    />
                </div>
            ))}
            {investmentRecordContext.data.documents?.map((doc) => (
                <div style={{ display: 'flex' }} key={doc.uid}>
                    <Paragraph
                        key={doc.uid}
                        onPress={() => onDocPress(doc.url)}
                        style={styles.normalText}
                    >
                        <b>{doc.title}</b>
                        {' '}- {doc.dateCreated.toFormat('dd LLL yyyy HH:mm')}
                    </Paragraph>
                    <FontAwesome
                        name="pencil"
                        onPress={() => onEditPress(doc)}
                        size={18}
                        color={'black'}
                        style={{ marginHorizontal: 15 }}
                    />
                </div>
            ))}

        </>
    )
}

export default DocumentList