import React from 'react'
import { Title, Subheading, Button, Dialog, Portal } from 'react-native-paper'
import styles from '../styles/generic.styles'

export type DeleteConfirmModalProps = {
    onConfirm: () => void,
    showModal: boolean
    onDismissCallback: () => void,
    confirmMessage: string
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({ onConfirm, showModal, onDismissCallback, confirmMessage }) => {

    return (
        <Portal>
            <Dialog visible={showModal} onDismiss={onDismissCallback}>
                <Dialog.Content>
                    <Title style={styles.heading}>{confirmMessage}</Title>
                    <Subheading style={styles.subheading}>This action cannot be reversed</Subheading>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={() => onConfirm()}>Yes</Button>
                    <Button color={'red'} onPress={onDismissCallback}>Cancel</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}

export default DeleteConfirmModal