/* eslint-disable import/no-duplicates */ // <--- Linter is not intelligent enough to know that we need the duplicates.
import { initializeApp, getApps, FirebaseApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import {
  Firestore, initializeFirestore, connectFirestoreEmulator,
} from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import Constants from 'expo-constants'

// You will get these configuration parts when you initially set up your Firebase project.
const devFirebaseConfig = {
    apiKey: "AIzaSyBhp2AlMdWQnKQmY-_iwddqe5nOUmY7coc",
    authDomain: "startup-dashboard-425e6.firebaseapp.com",
    projectId: "startup-dashboard-425e6",
    storageBucket: "startup-dashboard-425e6.appspot.com",
    messagingSenderId: "583711088153",
    appId: "1:583711088153:web:417eddb1f80d29d1979488",
    measurementId: "G-YMKETN40BZ"
}

const demoFirebaseConfig = {
  apiKey: 'TO_CONFIGURE',
  authDomain: 'TO_CONFIGURE',
  projectId: 'TO_CONFIGURE',
  storageBucket: 'TO_CONFIGURE',
  messagingSenderId: 'TO_CONFIGURE',
  appId: 'TO_CONFIGURE',
  measurementId: 'TO_CONFIGURE',
}

const prodFirebaseConfig = {
  apiKey: "AIzaSyAnIDwerk5ZNWd528pev7wjxIAYZy60gHM",
  authDomain: "startup-dashboard-prod.firebaseapp.com",
  projectId: "startup-dashboard-prod",
  storageBucket: "startup-dashboard-prod.appspot.com",
  messagingSenderId: "683309415324",
  appId: "1:683309415324:web:c5bdb031235051ebca6659",
  measurementId: "G-S76X4YL21M"
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
if (getApps().length === 0) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const appEnv = Constants.manifest?.extra?.env || 'DEV'
  let firebaseConfig = devFirebaseConfig
  if (appEnv) {
    switch (appEnv) {
      case 'PROD':
        firebaseConfig = prodFirebaseConfig
        break
      case 'DEMO':
        firebaseConfig = demoFirebaseConfig
        break
      case 'DEV':
      default:
        firebaseConfig = devFirebaseConfig
        break
    }
  }
  const app = initializeApp(firebaseConfig)
  if (!Constants.manifest?.extra?.emulatedFirebase) {
    switch (appEnv) {
      case 'PROD':
        initializeAppCheck(app, {
          provider: new ReCaptchaV3Provider('TO_CONFIGURE'),
          isTokenAutoRefreshEnabled: true,
        })
        break
      case 'DEMO':
        initializeAppCheck(app, {
          provider: new ReCaptchaV3Provider('TO_CONFIGURE'),
          isTokenAutoRefreshEnabled: true,
        })
        break
      case 'DEV':
        // initializeAppCheck(app, {
        //   provider: new ReCaptchaV3Provider('6Lf3XgoeAAAAAKB-Z_IUMVNz7Oiu_znezWWc3GAZ'),
        //   isTokenAutoRefreshEnabled: true,
        // })
        break
      default:
        break
    }
  }
  console.log(`Initialized firebase project with ID: ${firebaseConfig.projectId}`)
}

const initializeAppFirestore = (app: FirebaseApp): Firestore => {
  if (Constants.manifest?.extra?.emulatedFirebase) {
    return initializeFirestore(app, { experimentalForceLongPolling: true })
  }
  return initializeFirestore(app, { })
}

const app = getApps()[0]
const auth = getAuth(app)
const db = initializeAppFirestore(app)
const functions = getFunctions(app, 'europe-west2')
const storage = getStorage(app)

if (Constants.manifest?.extra?.emulatedFirebase) {
  console.log('Running in emulation mode for dev and testing')
  const hostname = window?.location?.hostname ?? 'localhost'
  connectFunctionsEmulator(functions, hostname, 5001)
  connectFirestoreEmulator(db, hostname, 8080)
  connectAuthEmulator(auth, `http://${hostname}:9099`)
  connectStorageEmulator(storage, 'localhost', 9199)
}

export {
  app, auth, db, functions, storage,
}
