import * as E from 'fp-ts/Either'
import * as T from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { failure } from 'io-ts/PathReporter'

export const decodeWith = <A, O>(codec: T.Type<A, O, unknown>, thing: unknown, printErrors = false): A | undefined => (
  pipe(
    codec.decode(thing),
    E.getOrElseW((errors: T.Errors) => {
      if (printErrors) {
        console.error(`Failed to decode with codec ${codec.name}:\n`)
        console.error(failure(errors).join('\n'))
      }
      return undefined
    }),
  )
)

export default decodeWith
