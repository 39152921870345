import React, { FC } from 'react'
import { ViewStyle } from 'react-native'
import { Button } from 'react-native-paper'

interface Props {
  onPress: () => void,
  loading?: boolean,
  disabled?: boolean,
  style?: ViewStyle
}

const ActionButton: FC<Props> = ({
  onPress, loading, disabled, children, style,
}) => (
  <Button
    style={style}
    mode="outlined"
    onPress={onPress}
    loading={loading}
    disabled={disabled}
    dark
    labelStyle={{ color: 'inherit' }}
  >
    {children}
  </Button>
)

export default ActionButton
