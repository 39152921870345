import { DateTime } from 'luxon';
import React, { useRef } from 'react'
import { Caption } from 'react-native-paper';
import { Event } from '../../types';
import { FontAwesome } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native';
import {
    useHover,
} from 'react-native-web-hooks';
import { calculateDuration, formatDuration } from '../DurationFunctions'

export type TimeLineArrowProps = {
    currentEvent?: Event,
    nextEvent?: Event,
}


const TimeLineArrow: React.FC<TimeLineArrowProps> = ({ currentEvent, nextEvent }) => {
    const navigation = useNavigation()
    const createEventOnClick = (startupUid: string, indexOrder: number) => {
        navigation.navigate('StartupScreens', { screen: 'CreateEvent', params: { startupUid: startupUid } } )
    }
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const getStartupUid = () => {
        if (currentEvent) {
            return currentEvent.startupUid
        }
        if (nextEvent) {
            return nextEvent.startupUid
        }
        return undefined
    }

    const getCreateStartupIndex = () => {
        if (currentEvent) {
            return currentEvent.indexOrder + 1
        }
        if (nextEvent) {
            return nextEvent.indexOrder - 1
        }
        return 0
    }


    const durationToDisplay = () => {
        if (nextEvent && !nextEvent.startDate && !nextEvent.expectedNextUpdateDate) {
            return ''
        }
        let startDate = currentEvent?.endDate
        if (!startDate) {
            startDate = currentEvent?.startDate
        }
        if (!startDate) {
            startDate = DateTime.now()
        }
        let endDate = nextEvent?.expectedNextUpdateDate
        if (!endDate) {
            endDate = nextEvent?.startDate
        }
        if (!endDate) {
            endDate = DateTime.now()
        }
        // console.log(`duration start=${startDate} end=${endDate}`)
        return formatDuration(calculateDuration(endDate, startDate))

    }


    return (

        <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }} onClick={() => {
            const startupUid = getStartupUid()
            if (startupUid) {
                createEventOnClick(startupUid, getCreateStartupIndex())
            }
        }

        }>

            <a style={{ cursor: 'cell', paddingTop: '30px' }}> <FontAwesome
                ref={ref}
                style={[{ paddingTop: '20px' }, isHovered && { color: 'green' }]}
                name={"arrow-right"}
                size={48}
                color={"gray"}
            /></a>
            <Caption>{durationToDisplay()}</Caption>
        </div >
    )
}

export default TimeLineArrow