import React, { useState, useEffect } from 'react'
import { Card, Caption, Divider } from 'react-native-paper'
import { Tooltip } from 'react-hover-tooltip'
import { Event, Comment, Document, StartUp } from "../../types"

import { useNavigation } from '@react-navigation/native';
import colors from '../../styles/colors';
import { DateTime, Duration } from 'luxon';
import EventIcon from './EventIcon';
import { FontAwesome } from '@expo/vector-icons'
import { Linking } from 'react-native';
import { sortCommentsByDate } from '../../util/SortList';
import { calculateDuration, formatDuration } from '../DurationFunctions'
import { EventContext, EventData } from '../../context/EventContext';
import { getAllDocumentsByEventListener, getCommentsListener } from '../../firestore_sync';

type CardSize = 'regular' | 'large'
export type EventTimeLineCardProps = {
    event: Event,
    startup: StartUp,
    lastCompleted: boolean,
    highlightDuration?: Duration,
    size?: CardSize
}

const EventTimeLineCard: React.FC<EventTimeLineCardProps> = ({ event,
    startup, lastCompleted,
    highlightDuration = Duration.fromDurationLike({ days: 3 }),
    size = 'regular' }) => {
    const navigation = useNavigation();
    const [eventData, setEventData] = useState<EventData>({
        event: event,
        comments: [],
        documents: []
    })

    const comments = sortCommentsByDate(eventData.comments).reverse()
    const documents = eventData.documents
    const [expanded, setExpanded] = useState(false)
    useEffect(() => {
        if (event && showComments()) {
            const unsubscribe = getCommentsListener(event.startupUid, event.uid, (newComments: Comment[]) => {
                console.log(`Comments updated from firebase for startup ${startup?.name} and event ${event.title}: ${newComments.length}`)
                setEventData(prevData => ({ ...prevData, comments: newComments }))
            }, 3)
            return () => {

                console.log(`DASH UNSUBSCRIBE from comments for startup ${startup?.name} and event ${event.title}`)
                unsubscribe()
            }

        }

    }, [event])

    useEffect(() => {
        if (event && showDocuments()) {
            const unsubscribe = getAllDocumentsByEventListener(event.uid, (newDocs: Document[]) => {
                console.log(`Documents updated from firebase for startup ${startup?.name} and event ${event.title}: ${newDocs.length}`)
                setEventData(prevData => ({ ...prevData, documents: newDocs }))
            }, 3)
            return () => {

                console.log(`DASH UNSUBSCRIBE from documents  for startup ${startup?.name} and event ${event.title}`)
                unsubscribe()
            }

        }

    }, [event])

    const cardExpandClick = () => {
        if (!expanded && comments.length == 0) {
            return
        }
        setExpanded(!expanded)
    }

    const cardEditClick = () => {

        navigation.navigate('StartupScreens', {
            screen: 'EventScreens', params: { screen: 'EventDetails', params: { startupUid: event.startupUid, eventUid: event.uid } }
        })
    }

    const lastUpdatedInWeeks = () => {
        if (event.startDate && !event.endDate) {
            return Math.floor(DateTime.now().toUTC().diff(event.lastModified).as('weeks'))
        }
        return 0
    }

    const latestDocumentForEvent = (eventUid: string): Document | undefined => {
        const eventDocs = documents.filter(d => d.eventUid === eventUid);
        const mostRecentDoc = eventDocs.length > 0 ? eventDocs.reduce((a, b) => (a.dateCreated > b.dateCreated ? a : b)) : undefined
        if (mostRecentDoc && mostRecentDoc.url) {
            return mostRecentDoc
        }
        return undefined;
    }

    const cardBackgroundColor = () => {
        if (event.endDate) {
            return startup.archived && lastCompleted ? 'black' : 'grey'
        } else if (event.startDate && event.startDate.startOf('day').toMillis() < DateTime.now().toUTC().toMillis() &&
            (!event.expectedNextUpdateDate || event.expectedNextUpdateDate.startOf('day').toMillis() < DateTime.now().toUTC().toMillis())) {
            if (startup.inProgress || startup.earlyPipeline || startup.incubator) {
                if (event.expectedEndDate && event.expectedEndDate.endOf('day').toMillis() < DateTime.now().toUTC().toMillis()) {
                    return 'red'
                }
                if (event.expectedNextUpdateDate && event.expectedNextUpdateDate.endOf('day').toMillis() < DateTime.now().toUTC().toMillis()) {
                    return 'red'
                }

                return lastUpdatedInWeeks() > 2 ? 'red' : colors.Primary
            }

            return colors.Primary
        } else { //infer future event
            return 'white'
        }
    }

    const cardDateDisplay = () => {
        if (event.startDate && event.startDate.toMillis() > DateTime.now().toMillis()) {
            return 'Expected start: ' + event.startDate?.toFormat('dd/LLL/yyyy')
        } else if (event.expectedNextUpdateDate && event.expectedNextUpdateDate.toMillis() > DateTime.now().toMillis()) {
            return 'Next update: ' + event.expectedNextUpdateDate?.toFormat('dd/LLL/yyyy')
        } else if (event.endDate) {
            return 'Ended: ' + event.endDate?.toFormat('dd/LLL/yyyy')
        } else if (event.expectedNextUpdateDate) {
            return 'Next update: ' + event.expectedNextUpdateDate?.toFormat('dd/LLL/yyyy')
        } else if (event.expectedEndDate) {
            return 'Expected end: ' + event.expectedEndDate?.toFormat('dd/LLL/yyyy')
        } else {
            return ''
        }
    }

    const cardTextColor = () => {
        const toColor = cardBackgroundColor() === 'white' ? 'black' : 'white'
        return toColor
    }

    const formatComment = (comment: Comment) => {
        if (!comment) {
            return ""
        }
        return `(${comment.dateCreated.toISODate()}) ${comment.comment}`

    }

    const boldIfRecent = (comment: Comment, doShorten: boolean) => {
        if (Math.floor(DateTime.now().diff(comment.dateCreated).as('days')) < 3) {
            return <><b key={'comm_' + comment.uid} style={{ fontWeight: 'bold' }}>[NEW!] {doShorten ? shorten(formatComment(comment), true) : formatComment(comment)}</b> </>
        } else {
            return doShorten ? shorten(formatComment(comment)) : formatComment(comment)
        }
    }
    const shorten = (input: string, recent: boolean = false) => {
        if (!input) {
            return ""
        }
        let limit = size === 'large' ? 75 : 60
        if (recent) {
            limit = limit - 6
        }
        if (input.length <= limit) {
            return input
        }
        return input.substring(0, limit) + '..'
    }

    const weekdaysBetween = (start: DateTime, end: DateTime) => {

        let weekdays: number
        if (end.weekNumber == start.weekNumber) {

            weekdays = Math.floor(end.diff(start).as('hours') / 24);
            if (end.weekday == 7 && start.weekday <= 6) {
                weekdays = weekdays - 1
            }
            if (end.weekday == 6 && start.weekday <= 5) {
                weekdays = weekdays - 1
            }
            if (weekdays < 0) {
                weekdays = 0;
            }

        } else {
            var first = DateTime.fromMillis(start.toMillis()).endOf('week'); // end of first week
            var last = DateTime.fromMillis(end.toMillis()).startOf('week'); // start of last week

            var weeks = Math.floor(last.diff(first).as('hours') / 24 / 7)
            var days = weeks * 5; // this will always multiply of 7
            var wfirst = 7 - start.weekday; // check first week
            if (start.weekday === 6) wfirst = wfirst - 1; // -1 if start with saturday 
            if ((start.hour > end.hour) || (start.hour == end.hour && start.minute > end.minute)) {
                wfirst = wfirst - 1;
            }
            var wlast = end.weekday - 1; // check last week
            if (end.weekday == 7) wlast = wlast - 1; // -1 if end with sunday
            weekdays = wfirst + Math.floor(days) + wlast; // get the total
            if (weekdays < 0) {
                weekdays = 0;
            }

        }

        // console.log(`Weekdays from ${start.toISO()} to ${end.toISO()} is ${weekdays} start:${start.weekday} end: ${end.weekday}`)

        return weekdays


    }
    const getBorderWidth = () => {

        if (weekdaysBetween(event.lastModified, DateTime.now()) < highlightDuration.days) {
            return 3;
        } else {
            return 0
        }
    }

    const showComments = () => {
        if (startup.archived) {
            return lastCompleted;
        } else {
            return !event.endDate && (event.startDate || event.expectedNextUpdateDate || event.expectedEndDate)
        }

    }
    const showDocuments = () => {
        if (startup.archived) {
            return lastCompleted;
        } else {
            return event.endDate || event.startDate || event.expectedNextUpdateDate || event.expectedEndDate
        }

    }

    const getCardWidth = () => {
        if (size === 'large') {
            return 400
        }

        return 300
    }
    const commentFontSize = size === 'large' ? 16 : 14
    return (
        <EventContext.Provider value={{
            data: eventData
        }}>
            <div key={'card_div_' + event.uid} style={{ paddingBottom: 10, marginRight: 10, marginLeft: 10 }}>

                <Card
                    style={{ width: getCardWidth(), height: expanded ? '100%' : 130, backgroundColor: cardBackgroundColor(), borderWidth: getBorderWidth(), borderColor: 'orange' }}
                    mode="elevated"
                    onPress={cardExpandClick}
                >
                    <Card.Title
                        titleStyle={{ color: cardTextColor(), fontSize: 15, minHeight: 34 }}
                        subtitleStyle={{ color: cardTextColor(), fontSize: 15, minHeight: 34 }}
                        title={

                            <div style={{ verticalAlign: 'middle', width: '100%' }}>
                                <div style={{ float: 'left', verticalAlign: 'middle' }}>
                                    <EventIcon event={event} color={cardTextColor()} />
                                    <Caption style={{ fontSize: 18, color: cardTextColor() }}><b>{event.title}</b></Caption>
                                </div>
                                <div style={{
                                    float: 'right',
                                    verticalAlign: 'bottom'
                                }}>

                                    <FontAwesome
                                        style={{ padding: 3 }}
                                        name={"edit"}
                                        size={16}
                                        onPress={cardEditClick}
                                        color={cardTextColor()}
                                    />
                                    {
                                        latestDocumentForEvent(event.uid) && (
                                            <Tooltip content={latestDocumentForEvent(event.uid)?.title} >
                                                <FontAwesome
                                                    style={{ padding: 3 }}
                                                    name={"paperclip"}
                                                    size={16}
                                                    onPress={() => Linking.openURL(latestDocumentForEvent(event.uid)?.url!)}
                                                    color={cardTextColor()}
                                                />

                                            </Tooltip>
                                        )
                                    }
                                    {
                                        event.projectTrackingUrl && (
                                            <Tooltip content={"Project tracking link"} >
                                                <FontAwesome
                                                    style={{ padding: 3 }}
                                                    name={"trello"}
                                                    size={16}
                                                    onPress={() => Linking.openURL(event.projectTrackingUrl!)}
                                                    color={cardTextColor()}
                                                />

                                            </Tooltip>
                                        )
                                    }
                                </div>

                            </div>

                        }
                        subtitle={
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {

                                        cardDateDisplay()


                                    }

                                </div >
                                {showComments() && comments.length > 0 && expanded && (
                                    <>
                                        <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />
                                        <div key={'comment0'} style={{ whiteSpace: 'pre-wrap', fontSize: commentFontSize }}>

                                            {comments.length > 0 && boldIfRecent(comments[0], false)}
                                        </div>
                                        <div key={'comment1'} style={{ whiteSpace: 'pre-wrap', fontSize: commentFontSize }}>
                                            {comments.length > 1 && boldIfRecent(comments[1], false)}
                                        </div>
                                        <div key={'comment2'} style={{ whiteSpace: 'pre-wrap', fontSize: commentFontSize }}>
                                            {comments.length > 2 && boldIfRecent(comments[2], false)}
                                        </div>
                                        <div style={{ float: 'right', fontSize: commentFontSize }}>
                                            <FontAwesome
                                                name='angle-double-up'
                                                onPress={cardExpandClick}
                                                size={18}
                                                color={cardTextColor()}
                                                style={{ marginHorizontal: 15 }}
                                            />
                                        </div>

                                    </>
                                )
                                }
                                {showComments() && comments.length > 0 && !expanded && (
                                    <>
                                        <Divider style={{ width: '100%', borderColor: 'black', height: '2px' }} />
                                        <div key={'comment-not-exp-0'} style={{ whiteSpace: 'pre-line', fontSize: commentFontSize }}>
                                            {comments.length > 0 && boldIfRecent(comments[0], true)}
                                            <div key={'unexp-comment-div'} style={{ float: 'right' }}><FontAwesome
                                                name='angle-double-down'
                                                onPress={cardExpandClick}
                                                size={18}
                                                color={cardTextColor()}
                                                style={{ marginHorizontal: 15 }}
                                            /></div>

                                        </div>

                                    </>
                                )
                                }


                            </>
                        }
                    />
                </Card >
                <div style={{ textAlign: 'center' }}>
                    {event.startDate && event.endDate && <Caption> {formatDuration(calculateDuration(event.endDate, event.startDate))}</Caption>}
                    {event.startDate && !event.endDate && <Caption> {formatDuration(calculateDuration(DateTime.now(), event.startDate))}</Caption>}
                </div>
            </div >
        </EventContext.Provider>
    )
}

export default EventTimeLineCard