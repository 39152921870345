import { createContext, useContext } from "react";


export const StartupUidContext = createContext<
    {
        startupUid: string | undefined,
        setStartupUid: (uid: string | undefined) => void
    }>({
        startupUid: undefined,
        setStartupUid: (uid: string | undefined) => { }
    })

const useStartupUidContext = (): {
    startupUid: string | undefined,
    setStartupUid: (uid: string | undefined) => void
} => {
    const context = useContext(StartupUidContext)
    return context
}

export default useStartupUidContext

