import React, { useEffect } from 'react'
import { View, ScrollView } from 'react-native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import DraggableEventCard from '../components/eventTimeline/DraggableEventCard';
import useGlobalContext from '../context/GlobalContext';
import useStartupUidContext from '../context/route/StartupUidContext';
import useStartupContext from '../context/StartupContext';
import { setEventsInFirestore } from '../firestore_sync';
import styles from '../styles/generic.styles';
import { Event } from '../types';
import { sortEventsByIndex } from '../util/SortList';

export type EditTimelineProps = {
    startupUid: string;
}

const EditTimeline: React.FC<EditTimelineProps> = ({ startupUid }) => {
    const { setStartupUid } = useStartupUidContext()

    useEffect(() => {
        setStartupUid(startupUid)
    }, [])

    const globalContext = useGlobalContext()
    const startupContext = useStartupContext()
    const startup = globalContext.data.startups.find(s => s.uid === startupUid)
    const events = sortEventsByIndex(startupContext.data.events)

    const renderTimeLine = ({ item, drag }: RenderItemParams<Event>) => {
        return (
            <View style={{ flex: 1 }}>
                {startup && <DraggableEventCard event={item} startup={startup} drag={drag} />}
            </View>
        )
    }

    const onDragFinish = async (data: Event[]) => {
        for (let i = 0; i < data.length; i++) {
            data[i].indexOrder = i
        }
        await setEventsInFirestore(data)
    }

    return (
        <View style={styles.screenContainer}>
            <ScrollView>
                <div style={{ marginBottom: '10' }}>
                    <DraggableFlatList
                        data={events}
                        onDragEnd={({ data }) => onDragFinish(data)}
                        keyExtractor={(item) => item.uid}
                        renderItem={renderTimeLine}
                        nestedScrollEnabled
                        style={{ flex: 1 }}
                    />
                </div>
            </ScrollView>
        </View>
    )
}

export default EditTimeline