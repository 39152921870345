import { createContext, useContext } from "react";
import { Document, Event, InvestmentRecord, StartUp } from "../types";

export type StartupData = {
    startup?: StartUp,
    events: Event[],
    investmentRecords: InvestmentRecord[],
    documents: Document[]

}

export const StartupContext = createContext<
    {
        data: StartupData
    }>({
        data: {
            events: [],
            investmentRecords: [],
            documents: []
        },
    })

const useStartupContext = (): {
    data: StartupData,

} => {
    const context = useContext(StartupContext)
    return context
}

export default useStartupContext

