import React from 'react'
import { Comment } from "../../types"
import { Card, Caption } from 'react-native-paper'


import styles from '../../styles/generic.styles';
import useEventContext from '../../context/EventContext';
import useGlobalContext from '../../context/GlobalContext';

export type CommentCardProps = {
    comment: Comment
}
const CommentCard: React.FC<CommentCardProps> = ({ comment }) => {

    const { data: { users } } = useGlobalContext()

    const userName = users.find(u => u.uid === comment.user)?.name

    return (
        <Card
            style={{ width: 500, marginBottom: 10 }}
            mode="elevated"
        >
            <Card.Title
                title={<Caption>{userName}</Caption>}
                subtitle={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Caption style={[styles.normalText]}>

                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {comment.comment}
                            </div></Caption>
                        <Caption style={{ color: 'black' }}>{comment.dateCreated.toFormat('dd LLL yyyy HH:mm')} </Caption>
                    </div>
                }
            >
            </Card.Title>
        </Card>
    )
}

export default CommentCard