import { collection, doc, setDoc, query, onSnapshot, updateDoc, orderBy, limit, getDocs } from "firebase/firestore";
import { compact } from "lodash";
import { DateTime } from "luxon";
import decodeWith from "../decodeHelper";
import { auth, db } from "../FirebaseSingleton";
import { LuxonDateTimeT } from "../LuxonTimestampCodec";
import { InvestmentRecord, InvestmentRecordT } from "../types";


export const setInvestmentRecordInFirstore = async (record: InvestmentRecord): Promise<void> => {
    await setDoc(doc(db, `/startups/${record.startupUid}/investmentrecords/`, record.uid),
        InvestmentRecordT.encode(record))
    return new Promise((resolve) => resolve())
}

export const getInvestmentRecordsListener = (startupUid: string, callback: (records: InvestmentRecord[]) => void) => {
    const q = (query(collection(db, `/startups/${startupUid}/investmentrecords`)))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const records: InvestmentRecord[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(InvestmentRecordT, doc.data())
        ))
        callback(records)
    })
    return unsub
}


export const getLatestInvestmentRecordsListener = (startupUid: string, callback: (records: InvestmentRecord[]) => void) => {
    const q = (query(collection(db, `/startups/${startupUid}/investmentrecords`), orderBy('recordDate', 'desc'), limit(1)))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const records: InvestmentRecord[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(InvestmentRecordT, doc.data())
        ))
        callback(records)
    })
    return unsub
}


export const updateLastModified = async (startupId: string, recordUid: string) => {
    await updateDoc(doc(db, `/startups/${startupId}/investmentrecords`, recordUid), {
        lastModified: LuxonDateTimeT.encode(DateTime.now().toUTC()),
        modifiedBy: auth.currentUser?.uid,
    })

}

export const getInvestmentRecordsInFirestore = async (startupUid: string, limitCnt: number): Promise<InvestmentRecord[] | undefined> => {
  try {
    const snap = await getDocs(query(collection(db, `/startups/${startupUid}/investmentrecords`), orderBy('recordDate', 'desc'), limit(limitCnt)))
    const { docs } = snap
    return compact(docs.map((doc) => decodeWith(InvestmentRecordT, doc.data())))
  } catch (err) {
    console.error(err)
  }
}
