
import { findLast, isEqual } from "lodash";
import { DataKey, LintGraphData } from "./LintCheckGraph";
import { TodoGraphData } from "./TodoGraph";
import { stringToColour } from "./util";

const isLastReferenceToProject = (data: LintGraphData|TodoGraphData, index: number, project: string) => {
  const last = findLast(data, (dateRecord) => {
    return Object.keys(dateRecord).find((key) => key === project)
  })
  return isEqual(data[index], last)
}

export const LintGraphLabel = (props: any, data: LintGraphData, project: string, key: DataKey) => {
  const { x, y, index } = props;
  const projectData = data[index][project] as Record<DataKey, number>

  if (!projectData) {
    return <div/>
  }

  const latestDateIndex = Object.keys(data).length-1
  if (isLastReferenceToProject(data, index, project) || latestDateIndex === index) {
    return (
      <CustomLabel project={project} label={`${project} (${projectData[key]})`} x={x} y={y}/>
    );
  }
  return <div/>
}

export const TodoGraphLabel = (props: any, data: TodoGraphData, project: string) => {
    const { x, y, index } = props;
    const todos = data[index][project]

    if (index !== Object.keys(data).length-1 && !isLastReferenceToProject(data, index, project)) {
      return <div/>
    } 
    return (
      <CustomLabel project={project} label={`${project} (${todos})`} x={x} y={y}/>
    );
}

const CustomLabel = ({project, label, x, y}: {project: string, label: string, x: number, y: number}) => (
  <text x={x} y={y} dy={-4} fill={stringToColour(project)} fontSize={16} fontWeight={'bold'} textAnchor="end" z={10}>
    {label} 
  </text>
);