import { createContext, useContext } from "react";


export const InvestmentReecordUidContext = createContext<
    {
        recordUid: string | undefined,
        setRecordUid: (uid: string | undefined) => void
    }>({
        recordUid: undefined,
        setRecordUid: (uid: string | undefined) => { }
    })

const useInvestmentReecordUidContext = (): {
    recordUid: string | undefined,
    setRecordUid: (uid: string | undefined) => void
} => {
    const context = useContext(InvestmentReecordUidContext)
    return context
}

export default useInvestmentReecordUidContext

