import * as React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { logInWithGoogle } from '../firestore_sync'
import useUserContext from '../context/userContext'
import styles from '../styles/generic.styles'
import * as Images from '../Images'
import { Caption, Button } from 'react-native-paper';

const LogIn: React.FC = () => {
    const user = useUserContext()

    return (
        <View style={localStyles.container}>
            <View style={styles.logoContainer}>
                <Image style={styles.image} source={Images.TSFLogo} />
            </View>
            {user && (
                <Caption style={localStyles.title}>Welcome back {user.name}</Caption>
            )}

            {!user && (
                <>
                    <Caption style={localStyles.title}>Please log in</Caption>
                    <Button onPress={logInWithGoogle}>Sign in</Button>
                </>
            )}
        </View>
    )
}

export default LogIn

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        margin: 15,
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
