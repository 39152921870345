import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { Platform, StyleSheet, View, Image } from 'react-native';
import { auth } from '../FirebaseSingleton';
import styles from '../styles/generic.styles'
import * as Images from '../Images'
import { Button, Caption } from 'react-native-paper';

export default function ModalScreen() {

  const signOut = async () => {
    await auth.signOut()
  }

  return (
    <View style={[styles.formContainer, localStyles.container]}>
      <View style={styles.logoContainer}>
        <Image style={styles.image} source={Images.TSFLogo} />
      </View>
      <Caption style={localStyles.title}>Settings</Caption>
      <View style={localStyles.separator} />
      <Button onPress={signOut}>Sign out</Button>
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
