import React from 'react'
import { View, ScrollView } from 'react-native';
import useEventContext from '../../context/EventContext';
import CreateCommentForm from '../../screens/forms/CommentForm';
import { sortCommentsByDate } from '../../util/SortList';
import CommentCard from './CommentCard';

export type CommentSectionProps = {
    eventUid: string;
    startupUid: string;
}

const CommentSection: React.FC<CommentSectionProps> = ({ startupUid, eventUid }) => {

    const { data } = useEventContext()

    const comments = sortCommentsByDate(data.comments).reverse()

    return (
        <View>
            <ScrollView>
                <CreateCommentForm startupUid={startupUid} eventUid={eventUid} />
                {comments.map((comment) => (
                    <CommentCard comment={comment} key={comment.uid} />
                ))}
            </ScrollView>
        </View>
    )
}

export default CommentSection