import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Event, Phase, StartUp } from '../../types';
import EventTimeLineCard from './EventTimeLineCard';
import { ScrollView } from 'react-native';
import TimeLineArrow from '../icons/TimeLineArrow';
import { isMobile } from '../../util/IsMobile';
import { Duration } from 'luxon';
import { sortEventsByIndex } from '../../util/SortList';


export type TimelineArchivedProps = {
    startup: StartUp,
    events?: Event[],
    highlightDuration?: Duration,
    phase: Phase
}

const TimelineArchived: React.FC<TimelineArchivedProps> = ({ startup, events, highlightDuration, phase }) => {

    const [current, setCurrent] = useState<Event | undefined>()
    const [last, setLast] = useState<Event | undefined>()

    const scrollView = useRef<ScrollView>(null)

    useLayoutEffect(() => {
        if (isMobile()) {
            scrollView.current?.scrollTo({ x: 0, y: 650, animated: true })
        }
    })

    useEffect(() => {
        if (events) { setNextCurrentLastEvent(events) }

    }, [events])

    const setNextCurrentLastEvent = (events: Event[]) => {
        const ordered = sortEventsByIndex(events)
        setLast(ordered.slice().reverse().find(e => e.endDate !== undefined))
        const current_candidate = ordered.find(e => e.endDate === undefined && e.startDate !== undefined)
        if (current_candidate) {
            setCurrent(current_candidate)
        } else {
            const allWithSetDate = ordered.slice().reverse().filter(e => e.endDate !== undefined)

            setCurrent(allWithSetDate.length > 0 ? allWithSetDate[0] : undefined)
            setLast(allWithSetDate.length > 1 ? allWithSetDate[1] : undefined)

        }

    }

    return (
        <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}
                horizontal={true}
                centerContent
                ref={scrollView}
                contentOffset={{ x: 500, y: 0 }}
            >
                <div>
                    {last
                        ? <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <EventTimeLineCard event={last} startup={startup} lastCompleted={false} highlightDuration={highlightDuration} />
                            </div>
                            {current && <TimeLineArrow currentEvent={last} nextEvent={current} />}
                        </div>
                        : <div style={{ width: 360 }} />
                    }
                </div>
                <div>
                    {current
                        ? <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <EventTimeLineCard event={current} startup={startup} lastCompleted={true} highlightDuration={highlightDuration} size={'large'} />
                            </div>

                        </div>
                        : <>
                            <div style={{ width: 460 }} />
                        </>
                    }
                </div>

            </ScrollView>
        </div>
    )
}

export default TimelineArchived