import React, { useState, useEffect } from "react";
import { FormikProps } from "formik";
import { isEqual } from "lodash";

type SubmitListenerProps = {
    formikProps: FormikProps<any>
}
export const SubmitListener: React.FC<SubmitListenerProps> = (
    { formikProps }
) => {

    const [lastValues, updateState] = useState(formikProps.values);

    useEffect(() => {

        const valuesEqualLastValues = isEqual(lastValues, formikProps.values);
        const valuesEqualInitialValues =
            formikProps.values === formikProps.initialValues;

        if (!valuesEqualLastValues) {
            updateState(formikProps.values);
            console.log('Submit state updated...')
        }

        console.log('Formik errors:' + JSON.stringify(formikProps.errors))

        if (!valuesEqualLastValues && !valuesEqualInitialValues) {
            console.log('Submitting form...')

            formikProps.submitForm()
        }

    }, [
        lastValues,
        formikProps.values,
        formikProps.initialValues,
        formikProps.handleChange,
        formikProps
    ]);

    return null;
};