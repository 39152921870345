import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Event, Phase, StartUp } from '../../types';
import { DateTime } from 'luxon';
import EventTimeLineCard from './EventTimeLineCard';
import { ScrollView } from 'react-native';
import TimeLineArrow from '../icons/TimeLineArrow';
import { isMobile } from '../../util/IsMobile';
import { Duration } from 'luxon';
import { sortEventsByIndex } from '../../util/SortList';
import InvestmentTimeLineCard from './InvestmentTimelineCard';


export type TimelineInvestmentProps = {
    startup: StartUp,
    events?: Event[],
    highlightDuration?: Duration,
    phase: Phase
}

const TimelineInvestment: React.FC<TimelineInvestmentProps> = ({ startup, events, highlightDuration, phase }) => {

    const [current, setCurrent] = useState<Event | undefined>()
    const [last, setLast] = useState<Event | undefined>()
    const [next, setNext] = useState<Event | undefined>()

    const scrollView = useRef<ScrollView>(null)

    useLayoutEffect(() => {
        if (isMobile()) {
            scrollView.current?.scrollTo({ x: 0, y: 650, animated: true })
        }
    })

    useEffect(() => {
        if (events) { setNextCurrentLastEvent(events) }

    }, [events])


    const setNextCurrentLastEvent = (events: Event[]) => {
        const ordered = sortEventsByIndex(events)
        setLast(ordered.slice().reverse().find(e => e.endDate !== undefined))
        const current_candidate = ordered.find(e => e.endDate === undefined)
        if (current_candidate?.startDate && current_candidate.startDate < DateTime.now().endOf('day')) {
            if (current_candidate.expectedNextUpdateDate && current_candidate.expectedNextUpdateDate > DateTime.now().endOf('day')) {
                setCurrent(undefined)
                setNext(current_candidate)
            } else {
                setCurrent(current_candidate)
                setNext(ordered.find(e => e.endDate === undefined && e !== current_candidate))
            }
        } else {
            setCurrent(undefined)
            setNext(current_candidate)
        }
    }

    return (
        <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}
                horizontal={true}
                centerContent
                ref={scrollView}
                contentOffset={{ x: 500, y: 0 }}
            >
                <div>
                    {last
                        ? <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <EventTimeLineCard event={current ? current : last} startup={startup} lastCompleted={last !== undefined} highlightDuration={highlightDuration} />
                            </div>
                            {(current || last) && <TimeLineArrow currentEvent={current ? current : last} nextEvent={undefined} />}
                        </div>
                        : <div style={{ width: 360 }} />
                    }
                </div>
                <div>
                    {<div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <InvestmentTimeLineCard startup={startup} highlightDuration={highlightDuration} size={'large'} />
                        </div>
                        {next && <TimeLineArrow currentEvent={undefined} nextEvent={next} />}
                    </div>
                    }
                </div>
                <div>
                    {next && phase !== 'archived'
                        ? <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <EventTimeLineCard event={next} startup={startup} lastCompleted={false} highlightDuration={highlightDuration} />
                            </div>
                        </div>
                        : <div style={{ width: 360 }} />
                    }
                </div>
            </ScrollView>
        </div>
    )
}

export default TimelineInvestment