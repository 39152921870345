import React, { FC, useState, useEffect } from 'react'
import * as DocumentPicker from 'expo-document-picker'
import { ref, uploadBytes } from 'firebase/storage'
import { v4 as uuid } from 'uuid'
import { Image } from 'react-native';
import { Caption } from 'react-native-paper';
import { storage } from '../../FirebaseSingleton'
import ActionButton from '../ActionButton'
import styles from '../../styles/generic.styles'
import { getImageUrlFromFirestoreStorage } from '../../firestore_sync';

const UploadImage: FC<{ prevImageUri?: string, imageUriCallback: (uri: string) => void }> = ({ imageUriCallback, prevImageUri }) => {
  const [uploading, setUploading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [imageUri, setImageUri] = useState<string>()

  useEffect(() => {

    (async () => {
      if (prevImageUri) {
        const url = await getImageUrlFromFirestoreStorage(prevImageUri)
        setImageUri(url)
      }
    })()


  })
  const upload = async () => {
    try {
      const fileToUpload = await DocumentPicker.getDocumentAsync({ type: 'image/*' })
      if (fileToUpload.type === 'success' && fileToUpload.file) {
        setImageUri(fileToUpload.uri)
        setUploading(true)
        const uploadFileName = uuid()
        const firestoreFileRef = ref(storage, uploadFileName)
        await uploadBytes(firestoreFileRef, fileToUpload.file)
        setImageUploaded(true)
        const imageUri = firestoreFileRef.toString()
        imageUriCallback(imageUri)
      }
    } catch (err) {
      console.error('Error uploading image to firebase.', err)
    } finally {
      setUploading(false)
    }
  }
  return (
    <>
      <ActionButton
        onPress={upload}
        loading={uploading}
        disabled={uploading || imageUploaded}
        style={styles.button}
      >
        Upload image
      </ActionButton>

      <>
        {imageUploaded && <Caption style={{
          textAlign: 'center',
          fontSize: 16,
          fontWeight: 'bold',
        }}>
          Image uploaded
        </Caption>
        }
        {imageUri &&
          <div style={{ width: 200, height: 200, paddingRight: 10 }}>
            <Image source={{ uri: imageUri }} style={{ width: '100%', height: '100%' }} />
          </div>
        }
      </>

    </>
  )
}

export default UploadImage