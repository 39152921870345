import React, { useEffect, useState } from 'react'
import { Image } from 'react-native'
import { getImageUrlFromFirestoreStorage } from '../firestore_sync'
import { StartUp } from '../types'
import * as Images from '../Images'

export type StartupLogoProps = {
    startup: StartUp
    width: number,
    height: number,
    onPress: () => void,
}

const StartupLogo: React.FC<StartupLogoProps> = ({ startup, width, height, onPress }) => {

    const [imageUrl, setImageUrl] = useState<string>()

    useEffect(() => {
        (async () => {
            if (startup.imageUri) {
                const url = await getImageUrlFromFirestoreStorage(startup.imageUri)
                setImageUrl(url)
            }
        })()
    }, [startup])

    return (
        <>
            {imageUrl && (
                <div style={{ width: width, height: height }} onClick={onPress}>
                    <Image
                        source={{ uri: imageUrl }}
                        style={{ width: '100%', height: '100%', padding: 10 }}
                    />
                </div>
            )}
            {!imageUrl && (
                <div style={{ width: width, height: height }} onClick={onPress}>
                    <Image
                        source={!startup.archived ? Images.NewLeadDefaultLogo : Images.ArchivedDefaultLogo}
                        style={{ width: '100%', height: '100%', padding: 10 }}
                    />
                </div>
            )}

        </>
    )

}

export default StartupLogo