import { compact } from 'lodash'
import {
    collection, query, getDocs, getDoc, doc, setDoc, deleteDoc, where, onSnapshot, orderBy, limit
} from 'firebase/firestore'
import { db } from '../FirebaseSingleton'
import { decodeWith } from '../decodeHelper'
import { Document, DocumentT } from '../types'

export const setDocumentInFirestore = async (document: Document): Promise<void> => {

    await setDoc(doc(db, `/documents`, document.uid), DocumentT.encode(document))
    return new Promise((resolve) => resolve())
}

export const getAllDocumentsByEventInFirestore = async (eventUid: string): Promise<Document[] | undefined> => {
    try {
        const snap = await getDocs(query(collection(db, `/documents`), where('eventUid', '==', eventUid)))
        const { docs } = snap
        return compact(docs.map((doc) => decodeWith(DocumentT, doc.data())))
    } catch (err) {
        console.error(err)
    }
}


export const getAllDocumentsByEventListener = (eventUid: string, callback: (documents: Document[]) => void, limitCnt: number = 10) => {
    const q = query(collection(db, `/documents`), where('eventUid', '==', eventUid), orderBy('dateCreated', 'desc'), limit(limitCnt))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const documents: Document[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(DocumentT, doc.data())
        ))
        callback(documents)
    })
    return unsub
}


export const getAllDocumentsByInvestmentRecordListener = (investmentRecordUid: string, callback: (documents: Document[]) => void, limitCnt: number = 10) => {
    const q = query(collection(db, `/documents`), where('investmentRecordUid', '==', investmentRecordUid))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const documents: Document[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(DocumentT, doc.data())
        ))
        callback(documents)
    })
    return unsub
}

export const getAllDocumentsByStartupListener = (startupUid: string, callback: (documents: Document[]) => void, limitCnt: number = 20) => {
    const q = query(collection(db, `/documents`), where('startupUid', '==', startupUid))
    const unsub = onSnapshot(q, (snapshot) => {
        const { docs } = snapshot
        const documents: Document[] = compact(docs.map(
            (doc: { data: () => unknown }) => decodeWith(DocumentT, doc.data())
        ))
        callback(documents)
    })
    return unsub
}

export const getAllDocumentsByStartupInFirestore = async (startupUid: string): Promise<Document[] | undefined> => {
    try {
        const snap = await getDocs(query(collection(db, `/documents`), where('startupUid', '==', startupUid)))
        const { docs } = snap
        return compact(docs.map((doc) => decodeWith(DocumentT, doc.data())))
    } catch (err) {
        console.error(err)
    }
}

export const getDocumentInFirestore = async (documentUid: string): Promise<Document | undefined> => {
    try {
        const snap = await getDoc(doc(db, `/documents`, documentUid))
        if (!snap.exists) {
            throw new Error('Failed to get document')
        }
        const document = decodeWith(DocumentT, snap.data())
        return document
    } catch (err) {
        console.error(err)
    }
}

export const deleteDocumentInFirestore = async (document: Document): Promise<void> => {
    try {
        await deleteDoc(doc(db, `/documents`, document.uid))
        return new Promise((resolve) => resolve())
    } catch (err) {
        console.error(err)
    }
}