import React from "react";
import { DateRange } from "./LintCheckGraph"
import { View, Text } from "react-native";
import { Button } from "react-native-paper";

const activeButtonStyle = {
  backgroundColor: 'blue',
  marginRight: '1em'
}

const inactiveButtonStyle = {
  marginRight: '1em'
}

const activeText = {
  color: 'white'
}

type Props = {
  dateRange: DateRange,
  setDateRange: (range: DateRange) => void;
}
export const DateRangeControls: React.FC<Props> = ({ dateRange, setDateRange}) => {
  
  const options: DateRange[] = ['ALL', 'YEAR', 'MONTH']

  return (
    <View style={{ display: 'flex', flexDirection: 'row', width: '1200px', justifyContent: 'flex-end'}}>
      {options.map((option) => (
        <Button 
          onPress={() => setDateRange(option)}
          mode={'outlined'}
          style={dateRange === option ? activeButtonStyle : inactiveButtonStyle}
        >
          <Text style={dateRange === option ? activeText : {}}>
            {option}
          </Text>
        </Button>
      ))}
    </View>
  )
}