import React, { FC, useMemo, useState, useEffect } from 'react'
import { View } from 'react-native'
import { TextInput, Caption } from 'react-native-paper'
import { FormikHandlers } from 'formik'
import { debounce } from 'lodash'

export interface TextInputBaseProps {
  value: string
  onChangeText: FormikHandlers['handleChange'] | ((e: string) => void)
  onBlur: FormikHandlers['handleBlur']
  autoFocus?: boolean
  placeholder?: string
  editable?: boolean
  label?: string
  multiLine?: boolean
}

export interface TextInputValidatedProps extends TextInputBaseProps {
  touched: boolean | undefined
  error: string | undefined
}

const TextInputValidated: FC<TextInputValidatedProps> = ({
  value, onChangeText, onBlur, autoFocus = false, placeholder,
  touched, error, editable = true, label, multiLine = false
}): JSX.Element => {
  const [valueLocal, setValueLocal] = useState(value)

  const debouncedOnChangeText = useMemo(
    () => debounce(onChangeText, 500)
    , []);

  const onChangeTextInternal = (newValue: string) => {
    setValueLocal(newValue)
    debouncedOnChangeText(newValue)
  }
  useEffect(() => {
    setValueLocal(value)
  }, [value])

  useEffect(() => {
    return () => {
      debouncedOnChangeText.cancel();
    }
  }, []);

  return (
    <View style={{ paddingBottom: 12 }}>
      <TextInput
        multiline={multiLine}
        numberOfLines={multiLine ? 4 : 1}
        autoComplete={""}
        value={valueLocal}
        onChangeText={onChangeTextInternal}
        onBlur={onBlur}
        autoFocus={autoFocus}
        placeholder={placeholder}

        editable={editable}
        style={{ color: 'black', width: '80%', margin: 8 }}
      />

      {(touched && error) && (
        <Caption style={{ color: 'red' }}>
          {(touched && error) ? (label ? label : '') + ' ' + error : label}
        </Caption>
      )}
      {!(touched && error) && (
        <Caption>
          {label ? label : ''}
        </Caption>
      )}
    </View>
  )
}

export default TextInputValidated
