import { useNavigation } from '@react-navigation/native'
import React, { useState, useEffect } from 'react'
import { StartUp, Event } from '../../types'
import EventTimeLineCard from './EventTimeLineCard';
import { ScrollView } from 'react-native';
import TimeLineArrow from '../icons/TimeLineArrow';
import { sortEventsByIndex } from '../../util/SortList';

export type StartupDetailsTimelineProps = {
    startup: StartUp
    events?: Event[]
}

const StartupDetailsTimeline: React.FC<StartupDetailsTimelineProps> = ({ startup, events }) => {
    const [toRender, setToRender] = useState<Event[]>([])

    useEffect(() => {
        if (events) {
            setToRender(sortEventsByIndex(events))
        }
    }, [events])


    const lastCompleted = (event: Event) => {
        const ordered = toRender.filter(e => e.endDate !== undefined).sort((e1: Event, e2: Event) => {

            if (!e1.endDate || !e2.endDate) {
                return 0;
            }
            return e2.endDate.toMillis() - e1.endDate.toMillis()
        })
        return ordered && ordered.length > 0 && ordered[0].uid === event.uid
    }

    return (
        <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row' }}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}
                horizontal={true}
                centerContent
            >
                {toRender.map((event) => (
                    <div key={'around_' + event.uid}>
                        {event && (
                            <div key={'torenderdiv_' + event.uid} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'top' }}>
                                <EventTimeLineCard key={'eventcard_' + event.uid} event={event} startup={startup} lastCompleted={lastCompleted(event)} />
                                {toRender.length !== (toRender.indexOf(event) + 1) && (
                                    <TimeLineArrow key={'arrow_' + event.uid} currentEvent={event} nextEvent={toRender[toRender.indexOf(event) + 1]} />
                                )}

                            </div>
                        )}
                        {!event && (
                            <div key={'none'} style={{ width: 450 }}></div>
                        )}
                    </div>
                ))}

            </ScrollView>
        </div >
    )
}

export default StartupDetailsTimeline