import { createContext, useContext } from 'react'
import { User } from '../types'
export const UserContext = createContext<User | undefined>(undefined)

const useUserContext = (): User | undefined => {
  const context = useContext(UserContext)
  return context
}

export default useUserContext
