import { compact } from 'lodash'
import {
  collection, query, getDocs, getDoc, doc, setDoc, deleteDoc, onSnapshot, updateDoc
} from 'firebase/firestore'
import { db, auth } from '../FirebaseSingleton'
import { decodeWith } from '../decodeHelper'
import { Event, EventT } from '../types'
import { LuxonDateTimeT } from '../LuxonTimestampCodec'
import { DateTime } from 'luxon'

export const setEventInFirestore = async (event: Event): Promise<void> => {
  await setDoc(doc(db, `startups/${event.startupUid}/events`, event.uid), EventT.encode(event))
  return new Promise((resolve) => resolve())
}

export const setEventsInFirestore = async (events: Event[]): Promise<void> => {
  events.forEach(async (event) => {
    await setDoc(doc(db, `startups/${event.startupUid}/events`, event.uid), EventT.encode(event))
  })
  return new Promise((resolve) => resolve())
}

export const getEventsListener = (startupId: string, callback: (events: Event[]) => void) => {
  const q = query(collection(db, `/startups/${startupId}/events`))
  const unsub = onSnapshot(q, (snapshot) => {
    const { docs } = snapshot
    const events: Event[] = compact(docs.map(
      (doc: { data: () => unknown }) => decodeWith(EventT, doc.data()),
    ))
    callback(events)
  })
  return unsub
}


export const updateLastModified = async (startupId: string, eventUid: string) => {
  await updateDoc(doc(db, `/startups/${startupId}/events`, eventUid), {
    lastModified: LuxonDateTimeT.encode(DateTime.now().toUTC()),
    modifiedBy: auth.currentUser?.uid,
  })

}

export const getAllEventsInFirestore = async (startupUid: string): Promise<Event[] | undefined> => {
  try {
    const snap = await getDocs(query(collection(db, `/startups/${startupUid}/events`)))
    const { docs } = snap
    return compact(docs.map((doc) => decodeWith(EventT, doc.data())))
  } catch (err) {
    console.error(err)
  }
}

export const getEventInFirestore = async (eventUid: string, startupUid: string): Promise<Event | undefined> => {
  try {
    const snap = await getDoc(doc(db, `/startups/${startupUid}/events`, eventUid))
    if (!snap.exists) {
      throw new Error('Failed to find event')
    }
    return decodeWith(EventT, snap.data())
  } catch (err) {
    console.error(err)
  }
}

export const deleteEventInFirestore = async (event: Event): Promise<void> => {
  try {
    await deleteDoc(doc(db, `/startups/${event.startupUid}/events`, event.uid))
    return new Promise((resolve) => resolve())
  } catch (err) {
    console.error(err)
  }
}
