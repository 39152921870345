import { StyleSheet, ViewStyle } from 'react-native'
import { color } from 'react-native-reanimated'
import { isMobile } from '../util/IsMobile'
import Colors from './colors'

export const BaseFontStyle = {
  fontFamily: 'Book',
  fontSize: 16,
}

const BaseScreenStyle: ViewStyle = {
  flex: 1,
  margin: 16,
}

const BaseButtonStyle = {
  marginTop: 10,
  marginBottom: 10,
  borderColor: 'black',
  color: 'black',
  width: isMobile() ? '80%' : '80%'
}

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  adminContainer: {
    flex: 1,
  },
  logoContainer: {
    height: 150,
    width: 150,
    marginBottom: 20,
  },
  image: { flex: 1, resizeMode: 'contain' },
  flexContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexContainerWithMargin: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    margin: 8,
    zIndex: 100,
  },
  flexDetailLine: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  screenContainer: BaseScreenStyle,
  screenContainerNoMargin: {
    ...BaseScreenStyle,
    margin: 0,
  },
  settingsScreenContainer: {
    ...BaseScreenStyle,
    justifyContent: 'flex-start',
  },
  formContainer: {
    ...BaseScreenStyle,
    maxWidth: 600,
    minWidth: 600,
    maxHeight: '100%',
    alignSelf: 'center'
  },
  baseFontStyle: BaseFontStyle,
  textInput: {
    ...BaseFontStyle,
    height: 50,
    paddingHorizontal: 8,
    width: '100%',
    borderWidth: 1,
    backgroundColor: '#fff',
    marginBottom: 8,
  },
  textArea: {
    ...BaseFontStyle,
    paddingHorizontal: 8,
    width: '100%',
    borderWidth: 1,
    backgroundColor: '#fff',
    marginBottom: 8,
    textAlignVertical: 'top',
  },
  textInputValidationMsg: {
    ...BaseFontStyle,
    marginBottom: 8,
    fontSize: 16,
    color: 'red',
    fontWeight: 'bold',
  },
  heading: {
    ...BaseFontStyle,
    fontSize: 32,
    paddingLeft: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  subheading: {
    ...BaseFontStyle,
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 10,
    textAlign: 'left',
    marginVertical: 5,
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    margin: 8,
    fontSize: 14,
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    ...BaseFontStyle,
    textAlign: 'center',
  },
  termsText: {
    ...BaseFontStyle,
    fontSize: 20,
    color: 'hsl(0, 0%, 25%)',
    textAlign: 'center',
  },
  normalText: {
    ...BaseFontStyle,
    textAlign: 'justify',
  },
  smallText: {
    ...BaseFontStyle,
    fontSize: 10
  },
  centerText: {
    ...BaseFontStyle,
    textAlign: 'center',
  },
  boldHeaderText: {
    ...BaseFontStyle,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 6,
  },
  scrollingView: {
    height: 1, // Hack to get the scrolling view working properly.
  },
  badgeView: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  widthFitContent: {
    width: 'fit-content',
  },
  button: {
    ...BaseButtonStyle,
  },
  destructiveButton: {
    ...BaseButtonStyle,
    backgroundColor: 'red',
    color: 'white'
  },
  activeButton: {
    ...BaseButtonStyle,
    backgroundColor: 'black',
    color: 'white'
  },
  eventCard: {
    backgroundColor: Colors.Tertiary,
    marginLeft: 20,
    marginBottom: 10,
    marginTop: 10
  },
  timeLineCard: {
    width: 200,
    height: 100,
    marginRight: 20,
  }
})

export default styles
