import { DateTime } from "luxon";

export const stringToColour = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export const formatDateString = (date: string) => {
  return date.split('-')[0].length === 2 
    ? DateTime.fromFormat(date, 'yy-M-d').toFormat('yyyy-MM-dd')
    : DateTime.fromFormat(date, 'yyyy-M-d').toFormat('yyyy-MM-dd')
}