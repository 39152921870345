import * as T from 'io-ts'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

export const LuxonDateTimeT = new T.Type<DateTime, Timestamp, unknown>(
  'LuxonDateTimeT',
  (u: unknown): u is DateTime => u instanceof DateTime,
  (u: unknown, c: T.Context) => {
    if (!(u instanceof Timestamp)) {
      return T.failure(u, c)
    }
    return T.success(DateTime.fromJSDate(u.toDate()).setZone('Europe/London'))
  },
  (a: DateTime) => Timestamp.fromDate(a.toJSDate()),
)
