import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { deleteDocumentInFirestore, setDocumentInFirestore } from "../../firestore_sync"
import styles from "../../styles/generic.styles";
import { View } from 'react-native'
import { Caption, Snackbar } from 'react-native-paper';
import { Formik, FormikHelpers } from 'formik'
import TextInputValidated from '../../components/forms/TextInputValidated'
import ActionButton from "../../components/ActionButton";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';
import { Document } from "../../types";
import { updateLastModified as updateLastModifiedStartup } from '../../firestore/startups';
import { updateLastModified as updateLastModifiedEvent } from '../../firestore/event';
import { updateLastModified as updateLastModifiedInvestmentRecord } from '../../firestore/investmentrecord';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import { SubmitListener } from './SubmitListener';
import { useNavigation } from "@react-navigation/native";
import Loading from '../../components/Loading';
import useEventContext from '../../context/EventContext';
import useInvestmentRecordContext from '../../context/InvestmentRecordContext';
import useStartupUidContext from '../../context/route/StartupUidContext';
import useEventUidContext from '../../context/route/EventUidContext';
import useInvestmentReecordUidContext from '../../context/route/InvestmentRecordContext';

export type DocumentFormProps = {
    eventUid?: string
    investmentRecordUid?: string
    startupUid: string
    documentUid?: string
    title?: string
}

const DocumentFormSchema = Yup.object({
    title: Yup.string().trim().required('Required'),
    url: Yup.string().url().trim().required('Required'),
})

type DocumentFormEntry = Yup.Asserts<typeof DocumentFormSchema>

const DocumentForm: React.FC<DocumentFormProps> = ({ eventUid, investmentRecordUid, startupUid, documentUid }) => {
    const { setStartupUid } = useStartupUidContext()
    const { setEventUid } = useEventUidContext()
    const { setRecordUid } = useInvestmentReecordUidContext()

    useEffect(() => {
        setStartupUid(startupUid)
        setEventUid(eventUid)
        setRecordUid(investmentRecordUid)
    }, [])


    const navigation = useNavigation()
    const eventContext = useEventContext()
    const investmentRecordContext = useInvestmentRecordContext()
    const [document, setDocument] = useState<Document>({
        uid: uuidv4(),
        eventUid: eventUid,
        investmentRecordUid: investmentRecordUid,
        startupUid: startupUid,
        dateCreated: DateTime.now(),
        url: '',
        title: '',
        lastUpdated: DateTime.now(),

    })
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [notifyText, setNotifyText] = useState('')


    useEffect(() => {

        if (documentUid) {
            setLoading(true)
            let foundDocument = eventUid ? eventContext.data.documents.find(d => d.uid === documentUid) : undefined
            if (!foundDocument && investmentRecordUid) {
                foundDocument = investmentRecordContext.data.documents.find(d => d.uid === documentUid)
            }
            if (foundDocument) {
                setDocument(foundDocument)
            } else {
                setError(true)
            }

        }
        setLoading(false)
    }, [documentUid, eventContext, investmentRecordContext])

    const handleSubmit = async (
        values: Document,
        formikActions: FormikHelpers<Document>) => {
        const cleanValues: DocumentFormEntry = DocumentFormSchema.validateSync(values)
        try {
            const currentDateTime = DateTime.now().toUTC()
            const newDocument: Document = {
                ...document,
                title: cleanValues.title,
                url: cleanValues.url,
                lastUpdated: currentDateTime.toUTC(),
            }
            const newDocWithoutUndefinedFields = Object.create({})
            for (const [key, value] of Object.entries(newDocument)) {
                if (value !== undefined) {
                    newDocWithoutUndefinedFields[key] = value;
                }
            }
            await setDocumentInFirestore(newDocWithoutUndefinedFields)
            await updateLastModifiedStartup(startupUid)
            console.log('updaing last modified...')
            eventUid && await updateLastModifiedEvent(startupUid, eventUid)
            investmentRecordUid && await updateLastModifiedInvestmentRecord(startupUid, investmentRecordUid)
            setNotifyText('Changes saved successfully')

        } catch (err) {
            setNotifyText('Error saving data, please try again later. If the problem persists contact support')
            console.error(err)
        }
    }
    if (loading) {
        return (<Loading />)
    }
    if (error) {
        return (
            <View style={styles.screenContainer}>
                <Caption style={styles.error}>Error, could not find document matching id</Caption>
            </View>
        )
    }

    return (
        <>
            <Snackbar
                visible={(notifyText && notifyText !== '') ? true : false}
                onDismiss={() => setNotifyText('')}
                duration={notifyText && notifyText.toLowerCase().includes('error') ? 300000 : 5000}
            >
                {notifyText}
            </Snackbar>
            <View style={styles.formContainer}>
                <Formik
                    initialValues={document}
                    validationSchema={DocumentFormSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (

                        <View>
                            <SubmitListener formikProps={props} />

                            <TextInputValidated
                                onChangeText={props.handleChange('title')}
                                onBlur={props.handleBlur('title')}
                                value={props.values.title}
                                placeholder={"Type here"}
                                label={"Title"}
                                touched={props.touched.title}
                                error={props.errors.title}
                            />
                            <TextInputValidated
                                onChangeText={props.handleChange('url')}
                                onBlur={props.handleBlur('url')}
                                value={props.values.url}
                                placeholder={"Type here"}
                                label={"URL"}
                                touched={props.touched.url}
                                error={props.errors.url}
                            />
                            <div>

                                {document && documentUid &&
                                    <>
                                        <ActionButton
                                            onPress={() => setShowDeleteModal(true)}
                                            style={styles.destructiveButton}
                                        >
                                            Delete Document
                                        </ActionButton>
                                        <DeleteConfirmModal confirmMessage={`Delete ${document.title} document?`} onConfirm={async () => {
                                            await deleteDocumentInFirestore(document)
                                            setShowDeleteModal(false)
                                            if (eventUid) {
                                                navigation.navigate('StartupScreens',
                                                            {
                                                                screen: 'EventScreens', params:
                                                                {
                                                                    screen: 'EventDetails', params: {
                                                                        eventUid: eventUid,
                                                                        startupUid: startupUid
                                                                    }
                                                                }
                                                            }
                                                        )
                                                
                                            } else {
                                                navigation.navigate('StartupScreens',
                                                            {
                                                                screen: 'StartUpDetails', params:
                                                                {
                                                                    startupUid: startupUid
                                                                }
                                                            }
                                                        )

                                            }
                                        }} showModal={showDeleteModal} onDismissCallback={() => setShowDeleteModal(false)} />

                                    </>
                                }

                            </div>
                        </View>
                    )
                    }
                </Formik >
            </View >
        </>
    )
}

export default DocumentForm