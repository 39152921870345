import React, { useState, useEffect } from 'react'
import { View, Image, Linking, ScrollView } from 'react-native';
import { getImageUrlFromFirestoreStorage } from '../firestore_sync';
import styles from '../styles/generic.styles'
import { useNavigation } from '@react-navigation/native';
import StartupDetailsTimeline from '../components/eventTimeline/TimelineStartupDetails';
import { Card, Paragraph, Subheading, Caption } from 'react-native-paper';
import { isMobile } from '../util/IsMobile';
import DocumentLibrary from '../components/documents/DocumentLibrary';
import { FontAwesome } from '@expo/vector-icons';
import ActionButton from '../components/ActionButton';
import * as Images from '../Images'

import DocumentList from '../components/documents/DocumentList';
import useGlobalContext from '../context/GlobalContext';
import useStartupContext from '../context/StartupContext';
import Loading from '../components/Loading';
import useStartupUidContext from '../context/route/StartupUidContext';
import { leadSources } from '../types';

export type StartUpDetailProps = {
  startupUid: string;
}

const StartUpDetails: React.FC<StartUpDetailProps> = ({ startupUid }) => {
  const { setStartupUid } = useStartupUidContext()

  useEffect(() => {
    setStartupUid(startupUid)
  }, [])

  const navigation = useNavigation()
  const globalContext = useGlobalContext()

  const { data: {
    startup, documents, events, investmentRecords
  } } = useStartupContext()
  const [logoUrl, setLogoUrl] = useState<string>()
  const assignees = startup?.assignees?.map(a => globalContext.data.users.find(u => u.uid === a)).map(a => a?.name)

  useEffect(() => {
    (async () => {
      if (startup) {
        try {

          const imageUrl = await getImageUrlFromFirestoreStorage(startup.imageUri)
          setLogoUrl(imageUrl)

        } catch (err) {
          console.error(err)
        }

      }
    })()
  }, [startup])

  const urlOnClick = async () => {
    if (startup && startup.url) {
      Linking.openURL(startup.url)
    }
  }

  const onEditTimelineClick = async () => {
    navigation.navigate('StartupScreens', { screen: 'EditTimeline', params: { startupUid: startup?.uid! } })
  }

  return (

    <View style={styles.screenContainer}>
      {!startup && <Loading />}
      {startup && (
        <ScrollView style={isMobile() ? {} : { paddingLeft: 20 }}>
          <div style={
            isMobile() ? { display: 'flex', flexDirection: 'column' }
              : { display: 'flex', flexDirection: 'row' }}>
            {logoUrl && (
              <div style={{ width: 200, height: 200, paddingRight: 10 }}>
                <Image source={{ uri: logoUrl }} style={{ width: '100%', height: '100%' }} />
              </div>
            )}
            {!logoUrl && (
              <div style={{ width: 200, height: 200, paddingRight: 10 }}>
                <Image source={!startup.archived ? Images.NewLeadDefaultLogo : Images.ArchivedDefaultLogo} style={{ width: '100%', height: '100%' }} />
              </div>
            )}
            <div style={
              !isMobile() ? { display: 'flex', flexFlow: 'column', width: '50%' }
                : { display: 'flex', flexDirection: 'column' }}>
              <Paragraph>Description: <b>{startup.description}</b></Paragraph>
              <Paragraph>Founder: <b>{startup.founder}</b></Paragraph>
              {startup.url && (
                <Paragraph
                  onPress={urlOnClick}
                >URL: <b>{startup.url}</b></Paragraph>
              )}
              <Paragraph>Contact details: <b>{startup.contact}</b></Paragraph>

              <Paragraph>Date created: <b>{startup.dateCreated.toFormat('dd LLL yyyy HH:mm')}</b></Paragraph>
              <Paragraph>Lead source: <b>{startup.leadSource ? leadSources[startup.leadSource] : '-'}</b></Paragraph>
              <Paragraph>Assignees: <b>{assignees?.toString()}</b></Paragraph>
              <Paragraph>In Progress: <b>{startup.inProgress ? 'Yes' : 'No'}</b></Paragraph>
              <Paragraph>Investment: <b>{startup.investment ? 'Yes' : 'No'}</b></Paragraph>
              <Paragraph>Archived: <b>{startup.archived ? 'Yes' : 'No'}</b></Paragraph>

              <Paragraph>Order: <b>{startup.order}</b></Paragraph>

            </div>
            <div style={
              !isMobile() ? { display: 'flex', flexFlow: 'column', width: '50%' }
                : { display: 'flex', flexDirection: 'column' }}>
              <Paragraph>Date of Investment: <b>{startup.investment ? startup.dateOfInvestment?.toFormat('LLL yyyy') : '-'}</b></Paragraph>
              {investmentRecords.length > 0 && <>

                <Subheading>Latest Investment Record</Subheading>
                <Paragraph>Record date: <b>{investmentRecords[0].recordDate.toFormat('LLL yyyy')}</b></Paragraph>
                <Paragraph>Current valuation: <b>£{investmentRecords[0].currentValuation}K</b></Paragraph>
                <Paragraph>Shareholding percentage: <b>{investmentRecords[0].shareholdingPercentage}%</b></Paragraph>
                <Paragraph>Current TSF value: <b>{investmentRecords[0].shareholdingPercentage && '£' + (investmentRecords[0].currentValuation / 100) * investmentRecords[0].shareholdingPercentage + 'K'}</b></Paragraph>
                <Paragraph>TSF Intitial Equity Value: <b>£{investmentRecords[0].tsfInvestmentValue}K</b></Paragraph>
                <Paragraph>TSF Cost: <b>£{investmentRecords[0].tsfCost}K</b></Paragraph>
                <Paragraph>Investmant status: <b>{investmentRecords[0].status}</b></Paragraph>
                <Paragraph>Commentary: <b>{investmentRecords[0].commentary}</b></Paragraph>
                {<><ActionButton style={styles.button} onPress={() => navigation.navigate('StartupScreens', {
                  screen: 'InvestmentRecordScreens', params: {
                    screen: 'CreateDocumentInvestment', params: {
                      recordUid: investmentRecords[0].uid,
                      startupUid: startup.uid!
                    }
                  }
                })}>
                  New Investment Document
                </ActionButton>
                  <DocumentList investmentRecord={investmentRecords[0]} />
                </>

                }
              </>}
            </div>
            {/* <div style={{width: '100%', height: 500, paddingTop: 10, display: 'flex', alignContent: 'center'}}>
                            <BudgetPieChart budget={50000} spent={23920}/>
                        </div>     */}
          </div>


          {
            startup && startup.investment && startup.investment === true &&
            <ActionButton
              onPress={() => navigation.navigate('StartupScreens', { screen: 'CreateInvestmentRecord', params: { startupUid: startup.uid!, previousRecordId: investmentRecords.length > 0 ? investmentRecords[0]?.uid : undefined } })}
              style={{ marginRight: 10, marginBottom: 10, borderColor: 'black', width: isMobile() ? '80%' : '30%' }}
            >
              <FontAwesome
                name="money"
                size={14}
                color={"black"}
              />
              New Investment Record
            </ActionButton>
          }

          <Card style={[styles.eventCard, { marginLeft: 'inherit' }]}>
            <Card.Content>
              <Caption key={'caption_' + startup.uid} style={styles.heading}>Time Line</Caption>
              <StartupDetailsTimeline startup={startup} events={events} />
            </Card.Content>
          </Card>
          {!isMobile() && (
            <ActionButton
              onPress={onEditTimelineClick}
              style={{ marginRight: 10, marginBottom: 10, borderColor: 'black', width: isMobile() ? '80%' : '30%' }}
            >
              <FontAwesome
                name="pencil"
                size={25}
                color={"black"}
              />
              Edit Timeline
            </ActionButton>
          )}
          <DocumentLibrary startupUid={startup.uid!} />

        </ScrollView>
      )
      }
    </View >

  )
}

export default StartUpDetails