import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { storage } from '../FirebaseSingleton'
import { User } from '../types'
import { getDownloadURL, ref } from 'firebase/storage';
import { createUserInFirestore } from "./user";

export const logInWithGoogle = async (): Promise<User> => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const { email, uid, displayName } = (await signInWithPopup(auth, provider)).user
    if (email && displayName) {
        const emailDomain = email.substring(email.indexOf('@') + 1)
        if (email != 'guyremond@gmail.com' && email != 'elliotjsmith1982@gmail.com' &&
            emailDomain !== 'thestartupfactory.tech' &&
            emailDomain !== 'ehe.capital') {
            throw new Error('Unauthenticated user')
        } else {
            const user: User = {
                uid, email, name: displayName
            }
            createUserInFirestore(user)
            return user
        }
    } else {
        throw new Error('Error fetching user email and display name')
    }
}
export const getImageUrlFromFirestoreStorage = async (imageUri: string | undefined): Promise<string | undefined> => {
    if (!imageUri) {
        return undefined
    }
    try {
        const newImageUrl = await (getDownloadURL(ref(storage, imageUri)))
        return newImageUrl
    } catch (e) {
        console.error('Error fetching image from firebase', e)
        return undefined
    }
}