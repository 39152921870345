import { createContext, useContext } from "react";
import { Document, InvestmentRecord } from "../types";

export type InvestmentRecordData = {
    record: InvestmentRecord | undefined,
    documents: Document[],


}

export const InvestmentRecordContext = createContext<
    {
        data: InvestmentRecordData
    }>({
        data: {
            record: undefined,
            documents: [],
        },
    })

const useInvestmentRecordContext = (): {
    data: InvestmentRecordData,

} => {
    const context = useContext(InvestmentRecordContext)
    return context
}

export default useInvestmentRecordContext

